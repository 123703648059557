import { React } from "react";
import NavBtns from "../components/NavBtns";
import LazyLoad from "react-lazy-load";

// import images
import BG from "../img/age-of-collaboration/Collaboration_artwork_no_circles_rq4qbg.jpg";
import KHALogo from "../img/age-of-collaboration/KHA_Logo-clear-white_kxx469.png";
import BLTaLogo from "../img/age-of-collaboration/BLTs_Logo-clear_iy3woi.png";
import VIALogo from "../img/age-of-collaboration/VIA_PE_Lockup_knockout_gx9b1x.png";
import PfeifferLogo from "../img/age-of-collaboration/Pfeiffer_Perkins_Eastman_Lockup_reversed_dq6p5c.png";
import MEISLogo from "../img/age-of-collaboration/Meis_PE_Lockup_White_CMYK_tqlyfe.png";
// import Wuxi from "../img/age-of-collaboration/Wuxi_full_site_c09-min.jpg";
import Griffith from "../img/age-of-collaboration/Pfeiffer_Griffith_Observatory-min.jpg";
import TaylorHall from "../img/age-of-collaboration/Pfeiffer_MRU_Taylor_Concert_Hall_Interior-min.jpg";
// import VIASpire from "../img/age-of-collaboration/fpz_20210627_0624-min.png";
import VIAMetro from "../img/age-of-collaboration/VIA_Metrotown_456-min-min.png";
import CivicCenter from "../img/age-of-collaboration/CivicCenter_3-4L-min.jpg";
// import Everton from "../img/age-of-collaboration/MEIS_Everton_riverfront_view.jpg";
import StaplesCenter from "../img/age-of-collaboration/MEIS_STAPLE10_200dpi.jpg";
import EastonStadium from "../img/age-of-collaboration/MEIS_2022-10-11_View_B_UCLA_Ballpark_Right_Field-min.png";
// import Live from "../img/age-of-collaboration/BLTa_210524_404-min.jpg";
import EastMarket from "../img/age-of-collaboration/BLTa_East_Market_Drone_Chestnut_Walk.jpg";
import OneCity from "../img/age-of-collaboration/BLTa_1401_Arch-One_City_exterior.jpg";
// import GPHLobby from "../img/age-of-collaboration/NYU_School_of_Global_Public_Health_Int_Lobby.png";
import LIRR from "../img/age-of-collaboration/KHA_LIRR_vertical_cropped-min.jpg";
import Pratt from "../img/age-of-collaboration/KHA_Pratt_Institute_Manhattan_Center_Int_Gallery.png";

function NewComponent(props) {
  return (
    <div className="page page-container the-age-of-collaboration">
      <NavBtns prev="/TOC" next="/the-wharf-at-long-last" />

      <div className="hero-wrapper">
        <div className="hero">
          <div className="hero-images">
            <img
              id="collaboration-artwork"
              src={BG}
              // src="https://res.cloudinary.com/nickpug/image/upload/q_60/v1670510749/The%20Narrative%202022/Collaboration_artwork_no_circles_rq4qbg.jpg"
              alt="Collaboration artwork"
            />
            <div id="pfeiffer">
              <img
                alt="Pfeiffer logo"
                src={PfeifferLogo}
                // src="https://res.cloudinary.com/nickpug/image/upload/q_60/v1669136647/The%20Narrative%202022/Pfeiffer_Perkins_Eastman_Lockup_reversed_dq6p5c.png"
              />
            </div>
            <div id="via">
              <img
                alt="VIA logo"
                src={VIALogo}
                // src="https://res.cloudinary.com/nickpug/image/upload/q_60/v1669136710/The%20Narrative%202022/VIA_PE_Lockup_knockout_gx9b1x.png"
              />
            </div>

            <div id="meis">
              <img
                alt="MEIS logo"
                src={MEISLogo}
                // src="https://res.cloudinary.com/nickpug/image/upload/q_60/v1669136608/The%20Narrative%202022/Meis_PE_Lockup_White_CMYK_tqlyfe.png"
              />
            </div>

            <div id="kha">
              <img
                alt="KHA logo"
                src={KHALogo}
                // src="https://res.cloudinary.com/nickpug/image/upload/q_60/v1669146727/The%20Narrative%202022/KHA_Logo-clear-white_kxx469.png"
              />
            </div>
            <div id="blta">
              <img
                alt="BLTa logo"
                src={BLTaLogo}
                // src="https://res.cloudinary.com/nickpug/image/upload/q_60/v1669141252/The%20Narrative%202022/BLTs_Logo-clear_iy3woi.png"
              />
            </div>
          </div>
          <div className="hero-text">
            <h2>The Age of</h2>
            <h1>collaboration</h1>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <h3 className="tagline">
            Perkins Eastman’s strategic
            <br />
            alliances spur creative synergies.
          </h3>

          <h6 className="by-line">by Trish Donnally</h6>
          <p className="caption">
            Since the summer of 2021, five exceptional firms have joined forces
            with Perkins Eastman. Pfeiffer Partners, VIA Architecture, MEIS
            Architects, BLT Architects, and Kliment Halsband Architects have
            become Perkins Eastman studios. Merging talents has reaped big
            benefits, producing projects where vision, resources, and expertise
            have coalesced into exciting new designs and collaborative
            opportunities.
          </p>
          <div className="single-col">
            {/* <div className="column"> */}
            <p>
              “It isn’t surprising that firms are merging at the level they are
              now. Architecture has become so increasingly complex, and we have
              entered the real age of collaboration,” says Robert Ivy, former
              CEO of the American Institute of Architects (AIA). For the last 30
              years, Ivy notes, the roster of people who’ve been necessary to
              achieve what clients and projects have demanded has grown
              exponentially—from the architect, engineer, landscape architect,
              planner, and perhaps a graphic designer to a list of consultants
              that might be 35 firms long. “What single entity can contain all
              of those experts in one house? Almost none.
            </p>
            <p>
              “The merger motive and movement,” as Ivy refers to it, “allows
              companies to enjoy the resources and strengths that any particular
              firm or group might bring to the entirety. What a wonderful idea.”
            </p>
            <p>
              When various disciplines and resources are rolled together,
              projects can be approached at radically different scales, Ivy
              continues:
            </p>
            <p className="indent">
              A firm like Perkins Eastman can [plan and design] an entire city,
              a waterfront, a neighborhood, an individual project, or a dwelling
              unit. . . . Part of the beauty of the entire merger idea is that
              you don’t have to cast about between projects for the next job and
              the next company to ally yourself with. Instead, you’ve
              essentially built a network of trusted partners and co-workers who
              can work and reciprocate through the existing network. There’s no
              due diligence required; there’s no familiarity to be achieved. The
              level of trust is enhanced. Therefore, the whole has a greater
              opportunity, one hopes, for a better outcome. Merging firms
              creates an automatic level of knowledge and trust in the resources
              of our colleagues. It’s a maturity that’s been happening in the
              marketplace for a long time.
            </p>

            {/* </div> */}
          </div>
        </div>
      </div>
      <LazyLoad offset={300}>
        <div className="parallax griffith"></div>
      </LazyLoad>
      <p className="parallax-caption">
        Perkins Eastman and Pfeiffer joined forces, competed in, and won an
        international competition to design Wuxi Taihu-Bay International Culture
        and Arts Exchange in Wuxi, China. Rendering Courtesy Perkins Eastman and
        Pfeiffer
      </p>
      <div className="section">
        <div className="static">
          <h3>Creating Symbiotic Relationships</h3>
          <p>
            Perkins Eastman, now one of the largest architecture firms in the
            world with more than 1,150 professionals, has grown steadily over 40
            years, in part through uniting with firms such as Larsen Schein
            Ginsberg Snyder, Ehrenkrantz Eckstut & Kuhn, LBL Architects, and
            ForrestPerkins.
          </p>
          <p>
            Several years ago, Perkins Eastman’s leadership strategized to
            expand its presence in California and the Northwest as well as in
            Texas and the Southeast.
          </p>
          <p>
            “We almost always find a respected regional firm to give credibility
            and an understanding of the region where we want to be,” says Brad
            Perkins, co-founder and chairman of Perkins Eastman, who has led the
            realization of several successful unions. Another strategy is to
            open a studio with select professionals, spread the word, and
            prepare for organic growth, as Perkins Eastman did in Pittsburgh,
            Raleigh, Austin, Dubai, and, most recently, Singapore.
          </p>
          <p>
            “Our goal is to establish design studios that are strong locally and
            regionally, and have national stature that can be part of a larger
            practice,” says Perkins Eastman Co-CEO and Executive Director Shawn
            Basler. He underscores other vital qualities when joining forces.
            “The cultures have to align. We want firms to join us that believe
            in our culture and are compatible with us. This not only expands our
            business opportunities, but enhances our culture,” Basler says.
            “That’s what all five of the firms that joined us in the last couple
            of years&nbsp;do.”
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Griffith}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1668713358/The%20Narrative%202022/Pfeiffer_Griffith_Observatory_5572_N207_large_fjlks1.jpg"
                alt="Pfeiffer Griffith Observatory"
              />
            </LazyLoad>

            <h6>
              Pfeiffer—A Perkins Eastman Studio renovated and expanded the
              iconic Griffith Observatory in Los Angeles by almost 40,000 sf,
              most of it below grade. Copyright Tim Griffith/Courtesy Pfeiffer
            </h6>
          </div>
          <p>
            Basler also emphasizes combining a talent pool and leadership to
            create a portfolio that neither firm could have fostered
            independently. “What can we be doing together that we can’t do
            separately?” he asks. “Maybe it’s expanding our core markets,
            expanding geographically, or expanding into project types that a
            combined portfolio helps us get into.”
          </p>
          <p>
            Chemistry counts too. “Portfolios are one thing, but jobs are won by
            people and how well they work together,” Basler stresses. “You can
            have the best story in the world on a portfolio, but if the people
            in the interview going after a project aren’t in harmony, it doesn’t
            work. Close collaborations are something we’ve strived for, and
            they’ve been working.”
          </p>{" "}
          <h3>“An Absolute Dream Come True”</h3>
          <p>
            The recent competition win to design Wuxi Taihu-Bay International
            Culture and Arts Exchange in Wuxi, China, a city some 85 miles from
            Shanghai, crystallizes the success of the collaborative effort
            between Perkins Eastman and Pfeiffer, a firm founded in 1969 and
            known for its expertise in performing arts, libraries, historic
            preservation, and civic design.
          </p>
          <p>
            “Wuxi would never have been a win for us without having a merger.
            This project is almost literally what we had hoped for coming into
            the merger,” says Alberto Cavallero, design principal of Pfeiffer— A
            Perkins Eastman Studio.
          </p>
          <p>
            In fact, Perkins Eastman won the opportunity last spring to design
            the Wuxi Taihu-Bay International Culture and Arts Exchange after a
            Herculean twomonth effort that involved Pfeiffer’s studios in Los
            Angeles and New York and Perkins Eastman’s studios in New York and
            Shanghai. The effort included working long hours through a lockdown
            in Shanghai. The winning design concept, “Moonrise,” mixes culture
            and civic life. The design radiates from the stage of its
            world-class, 1,500-seat symphony hall. The cultural program includes
            other music-performance sites and a public-engagement zone.
            Altogether, this curated arts and retail venue creates a unique
            cultural, leisure, and entertainment destination—and a civic
            place—for Wuxi’s new Xinfa District.
          </p>
          <p>
            “We [Pfeiffer] had hoped to expand the reach that we already have in
            the arts world regionally and size-wise. What we were thinking then,
            and it still applies, was to expand . . . [in the] east,” Cavallero
            says. “We just weren’t expecting to expand this far east—to China!”
          </p>
          <p>
            The merger also strengthened Perkins Eastman’s ability to compete
            and win in Wuxi, given that a significant portfolio of work in the
            performing arts was an expectation to create a cutting-edge acoustic
            space for the city. “Conversely, I don’t think Pfeiffer,
            independently, would have been considered—we may not have even heard
            about this kind of endeavor. We didn’t have the strong Shanghai
            office that we have [now through Perkins Eastman].” Having a strong
            Chinese presence was a given for entering the competition.
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={TaylorHall}
                src="https://res.cloudinary.com/nickpug/image/upload/v1668713481/The%20Narrative%202022/Pfeiffer_MRU_Taylor_Concert_Hall_Interior_ljtaia.jpg"
                alt="Pfeiffer_MRU Taylor Concert Hall Interior"
              />
            </LazyLoad>

            <h6>
              The Mount Royal University Taylor Centre for the Performing Arts,
              designed by Pfeiffer, is a concert hall that provides high-quality
              instructional and performance spaces in Calgary, Alberta, Canada.
              Copyright Ema Peter/Courtesy Pfeiffer
            </h6>
          </div>
          <p>
            “This has been an absolute dream come true to win and execute, and
            we hope to parlay this into other major cultural projects,”
            Cavallero says.
          </p>
          <p>
            “Expansion of our horizon is really the biggest benefit of the
            merger. It’s opened up other worlds to us that we couldn’t have
            [otherwise],” says William Murray, a Pfeiffer principal based in Los
            Angeles. Murray considers Perkins Eastman’s 24 worldwide studios
            another strong asset. “The idea that you can say to a potential
            client, ‘We have a Charlotte office, or we have a Raleigh office, or
            we have a Pittsburgh office’ . . . is a great opportunity.” He adds,
            “I was just talking to a person at VIA about a performing arts
            center I’ve been working on for years in Bellevue, WA, and when that
            starts up . . . it will be great to tell the client, ‘We have an
            office just across the lake in Seattle.’”
          </p>
          <p>
            “All aspects of our professional lives have, by and large,
            improved,” says Jean Gath, who was Pfeiffer’s comanaging principal
            before she assumed an expanded role as co-managing principal of
            Perkins Eastman New York. “This is the perfect meld, because we
            don’t do science, we certainly don’t do healthcare, we don’t do
            senior living—things at which Perkins Eastman excels. In turn,
            Perkins Eastman doesn’t do, to the same extent as we do, performing
            arts or academic libraries. Our two firms are coming together in
            ways that really complement each other without getting in one
            another’s way.”
          </p>
          <p>
            For Pfeiffer’s team, the transition from a staff of 37 to more than
            1,000 has required some adjustments. Making decisions could be
            expedited with a small group, for instance, but the benefits have
            outweighed the challenges. In particular, Gath has been pleasantly
            surprised by Perkins Eastman’s culture. She credits Brad Perkins and
            Mary-Jean Eastman, co-founders and chairman and vice chair
            respectively, for building a global firm while maintaining the
            culture of a smaller one. “They’ve kept that homegrown, familiarity
            culture that firms of this size typically don’t have. It’s far less
            bureaucratic than we expected. Brad said, ‘We’re bringing you in to
            do what you do, why would we want to change what you do?’ That’s one
            thing for Brad to say, but another thing to actually have it be the
            case,” Gath says.
          </p>
        </div>
      </div>
      <LazyLoad offset={300}>
        <div className="parallax via-spire"></div>
      </LazyLoad>

      <p className="parallax-caption">
        VIA designed Spire, a 440-foot-tall luxury condominium tower that
        enhances Downtown Seattle’s recent building boom. Copyright Francis
        Zera/ Courtesy of VIA
      </p>
      <div className="section">
        <div className="static">
          <h3>Satisfying Intellectual Curiosity</h3>
          <p>
            Mergers do not happen overnight; timing is everything. “We
            consciously started talking to VIA over five years ago,” Perkins
            says. “We wanted to have a respected presence in the Northwest. They
            have experience and expertise in areas such as transit facilities,
            infrastructure, and multifamily residential. And we’re always
            interested in firms with strong planning components, which VIA has.
            We’re often involved in projects where we do the planning before any
            architectural projects begin, and we often end up doing planning,
            consulting, and building.” VIA, established in 1984, has 70-plus
            employees with studios in Seattle, WA; Oakland, CA; and Vancouver,
            British Columbia, Canada.
          </p>
          <p>
            “The main thing that compelled us to do this is that we saw a
            similar culture,” says Seattle-based Wolf Saar, managing principal
            of VIA—A Perkins Eastman Studio. “We like the idea of thinking of
            the firm as one entity rather than as siloed profit centers. That’s
            a very successful model; that’s the way we operated with three
            offices, so that was a positive.” Saar notes that expanding on the
            West Coast has created unexpected opportunities. “The fact that
            Pfeiffer was joining was an added value to us and the same for MEIS
            here on the West Coast. It made for larger critical mass of
            personnel, offices, and reach. And we’ve already found that we’re
            working very well with our West Coast brethren.” For example, VIA
            staffers have been working on Pfeiffer performing arts and college
            and university work as well as K-12 projects with Perkins Eastman in
            Costa Mesa and Oakland.
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={VIAMetro}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669154057/The%20Narrative%202022/VIA_Metrotown_456-min_uohoyx.png"
                alt="VIA Metrotown"
              />
            </LazyLoad>

            <h6>
              VIA—A Perkins Eastman Studio, which specializes in transit work
              among other practice areas, designed Metrotown SkyTrain Station
              Renovation in Burnaby, British Columbia, Canada. Copyright Ed
              White Photographics/Courtesy VIA
            </h6>
          </div>
          <p>
            “We’re diving into new markets,” adds Lauren Hamilton, managing
            principal of VIA, who is based in Vancouver. “I couldn’t have
            imagined the depth of opportunities for cross marketing and
            expansion of markets.” Hamilton highlights the common culture many
            West Coast teams share, which has created a bond among the eight
            studios that span from Perkins Eastman in Costa Mesa to VIA in
            Vancouver. “It comes out really strongly in terms of looking for
            work together, and we’ve had some success stories there.”
          </p>
          <p>
            Hamilton emphasizes another unexpected benefit she extolls as
            “hugely positive.”
          </p>
          <p>
            “Coming out of the pandemic, morale was down here, across the board,
            across the industry, and across the world. People had their heads
            down, waiting to see what would happen next, and afraid to make any
            Satisfying Intellectual Curiosity 6 Features moves for such a long
            time,” she says. But, as things started to pick up, Hamilton
            leveraged the benefits of being part of a larger constellation of
            studios:
          </p>
          <p className="indent">
            What people really needed was a sense of power to make changes in
            their lives. VIA would have lost a lot more people had we not been
            able to say, “This is a whole new change. Look, you can now go work
            on a senior living project in San Francisco, you can work on a
            hospital project in Los Angeles.” People got the experience of
            change, and feeling even some ownership, because we said, “Hey, do
            you want to go work on this? What would be exciting to you?”
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={CivicCenter}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136509/The%20Narrative%202022/CivicCenter_3-4L_2_nh1jib.jpg"
                alt="Civic Center"
              />
            </LazyLoad>

            <h6>
              VIA designed this floating horizontal entrance canopy for the Bay
              Area Rapid Transit (BART) system on Market Street in San
              Francisco. Many more entrance canopies are planned based on this
              prototype. Copyright Mike Sanchez Photography/Courtesy VIA
            </h6>
          </div>
          <p>
            Julia Bartmanska, an architectural designer at VIA in Vancouver who
            previously lived and worked in Poland, is among the people embracing
            change. Since joining VIA in 2019, she’s focused on multifamily
            housing and urban design. After the firm became part of Perkins
            Eastman, however, she says her opportunities broadened from her base
            in Canada to new collaborations with colleagues in San Francisco on
            senior living projects in California. “I had personally never worked
            on senior living residential projects,” says the designer, who is
            happy to be expanding her repertoire.
          </p>
          <p>
            Bartmanska has since traveled to San Francisco to work alongside her
            new team members in person. “It definitely helps with pushing the
            design process faster,” she says, and “it’s nice to develop stronger
            connections.”
          </p>
          <p>
            Having new options has made Bartmanska and other VIA staff members
            want to stretch their design muscles. “It has satisfied a lot of the
            itchiness in the intellectual curiosity that was tamped down during
            the pandemic when people were just trying to hold steady and make it
            through,” Hamilton says. “People thought ‘I need some change, I want
            some change, I want to assert some new agency in my life,’ and this
            [union] came at the right time to be able to offer that to people,
            while keeping them here.” People were missing the chance to travel,
            to interact socially, to challenge themselves. “We had nothing to
            stimulate us for two years. And the job became the easiest way to do
            it. Only because of this merger have we been providing depth and
            variety,” Hamilton says.
          </p>
        </div>
      </div>
      <LazyLoad offset={300}>
        <div className="parallax everton"></div>
      </LazyLoad>
      <p className="parallax-caption">
        MEIS designed Everton Stadium for a waterfront site in Liverpool,
        England. It is currently under construction and due to open in 2024.
        Rendering Courtesy MEIS
      </p>
      <div className="section">
        <div className="static">
          <h3>Playing with a Deep Bench</h3>
          <p>
            MEIS—A Perkins Eastman Studio is a powerhouse sports and
            entertainment firm that specializes in the design of experience and
            spectacle. MEIS is known for activating entertainment destinations
            through placemaking and urban planning and creating environments
            that inspire people to gather and celebrate. Founder Dan Meis, whom
            Time magazine recognized among its “100 Innovators in the World of
            Sports” in 2001, leads the practice, which has studios in Los
            Angeles and New York. Current projects, among others, include the
            new Everton Stadium in Liverpool, England, and the renovation of the
            Crypto.com Arena, formerly the Staples Center in Los Angeles, which
            Meis designed in 1999. The latter is a game-changing project that
            consistently ranks among the highest revenue-producing buildings in
            North America.
          </p>
          <p>
            “We could never get into large-scale sports without the portfolio
            and the brand name, and Dan Meis is just so well known in that
            arena,” Basler says. “It would have taken us decades to get there,
            and we gave him a larger platform to sell from, plus support. Having
            this platform has allowed us to accommodate the fast growth we’ve
            had. I don’t think any of us expected how fast it would grow,”
            Basler adds. Perkins Eastman and MEIS had teamed up on pursuits
            prior to joining forces, and the strategic partners are currently
            collaborating on multiple projects as well.
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={StaplesCenter}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669212832/The%20Narrative%202022/MEIS_STAPLE10_200dpi2_brbvxr.jpg"
                alt="Staples Center"
              />
            </LazyLoad>

            <h6>
              MEIS—A Perkins Eastman Studio is renovating the former Staples
              Center in Los Angeles. Originally designed by Dan Meis in 1999,
              the complex has been renamed the Crypto.com Arena. Copyright John
              Edward Linden/ Courtesy MEIS
            </h6>
          </div>
          <p>
            “Dan’s been a great partner to bring in on a lot of other projects,”
            Basler says. Little Caesars Arena in Detroit is one example where
            space, including a large plaza outside the building, is being
            analyzed and reimagined. Meis says the project is sports related,
            because it’s outside an arena and similar to other projects he’s
            designed, but the client was also interested in the portfolio of
            Hilary Kinder Bertsch, principal and executive director of Perkins
            Eastman, and her extensive work with public realm design. “It was
            another time when the client was very vocal about, you know, ‘We see
            you guys bringing both sides of this equation together,’” Meis says.
          </p>
          <p>
            “This is exactly why we joined each other,” Basler says. “You put
            our large-scale practice together with the sports practice and you
            think about the whole entertainment district. . . . It’s not just
            the arena or the stadium. It’s about what happens around it, and how
            that becomes seamless, because sometimes those Playing with a Deep
            Bench 8 Features [spaces] are designed independently, and being able
            to have an integrated approach inside and out makes a big
            difference.”
          </p>
          <p>
            Another new—and confidential—project combines the talents of MEIS,
            Perkins Eastman, and Pfeiffer in an unexpected way. “It’s a fun
            project in New York. I don’t often get to do projects in my home
            base, whether it’s New York or LA. The venue hosts ultraelectronic
            music. It’s a rave kind of place,” Meis says. The combined portfolio
            of the three practices helped Perkins Eastman win the job. “It
            clearly was Dan’s relationship, but Dan’s relationship combined with
            Pfeiffer’s performing arts centers and understanding of them,
            combined with what Francisco [Tsai of Perkins Eastman] does, and his
            understanding of performance venues and sound stages. It all came
            together,” Basler says.
          </p>
          <p>And that’s not all.</p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={EastonStadium}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669212918/The%20Narrative%202022/MEIS_2022-10-11_View_B_UCLA_Ballpark_Right_Field_min_vcm3mv.png"
                alt="UCLA Ballpark"
              />
            </LazyLoad>

            <h6>
              MEIS, Perkins Eastman, and Pfeiffer—A Perkins Eastman Studio, have
              teamed to create a master plan for the UCLA Easton Stadium.
              Rendering Courtesy MEIS
            </h6>
          </div>
          <p>
            MEIS, Pfeiffer, and Perkins Eastman have also teamed up to create a
            master plan for the UCLA Easton Stadium. “I’ve never been able to
            really go after the college market because they always saw me as the
            guy that does big pro buildings, and it was kind of a different
            market. Even though it was LA, that still would have been a struggle
            for me,” Meis says, so the team leveraged the relationship
            Pfeiffer’s Jean Gath had with UCLA. “They were interested in all of
            us. They had a history with Pfeiffer, they knew me in LA, and they
            were interested in Perkins Eastman and the kind of master planning
            the firm could bring to the project,” Meis says, referring to the
            growing college and sports practice led by Principal Scott
            Schiamberg. “It was a really good example of things we couldn’t have
            done individually,” Meis says. Plus, he adds:
          </p>
          <p className="indent">
            It really shows the power of bringing the portfolios together, and
            it’s not just added capacity and added staff, it really exemplifies
            synergistic experiences. Joining Perkins Eastman was really about
            the belief that together we could do something we couldn’t on our
            own, but it was also important for me to be able to maintain a brand
            that has distinguished us in sports architecture from the other
            larger firms. The opportunity to approach clients as a global,
            diverse firm but with the identity and feel of a boutique specialty
            practice really sets us apart from our competition.
          </p>
        </div>
      </div>
      <LazyLoad offset={300}>
        <div className="parallax live"></div>
      </LazyLoad>
      <p className="parallax-caption">
        Live! Casino & Hotel Philadelphia is but one example in BLTa’s extensive
        hospitality and gaming portfolio. Copyright Jeffrey Totaro/Courtesy BLTa
      </p>
      <div className="section">
        <div className="static">
          <h3>Hitting the Jackpot</h3>
          <p>
            BLTa—A Perkins Eastman Studio, as the firm is now known, commands a
            respected presence in the greater Philadelphia region, where it has
            completed more than 500 projects in 60 years. The integrated
            architecture and interior design firm of 46 professionals built its
            venerable business with expertise in multifamily residential work,
            higher education, historic renovation, adaptive reuse, and
            transit-oriented projects. BLTa also boasts an exceptional
            hospitality and gaming portfolio, but more on that later.
          </p>
          <p>
            Perkins Eastman has long sought a robust partner in the City of
            Brotherly Love, and, in BLTa, the firm found like-minded colleagues
            with deep local roots who were ready to spread their wings. “The
            synergy for us is that we can take the things we do really well to a
            broader geographic platform, and Perkins Eastman can bring its
            expertise in healthcare, higher education, and senior living into
            the Philadelphia region,” says Eric Rahe, a principal of BLTa, who
            joined the firm in 1987. Perkins Eastman’s studio in Pittsburgh,
            established 27 years ago, provides a strong presence in Western
            Pennsylvania. Now, the addition of the studio in Philadelphia
            strengthens the firm’s presence in Eastern Pennsylvania.
          </p>
          <p>
            Back to gaming. “BLTa has gaming experience, and that’s been huge,”
            Basler says. The firm’s casinos and hotels include Borgata Hotel
            Casino & Spa, The Water Club, and Ocean Casino Resort (formerly
            Revel), all in Atlantic City, as well as Live! Casino & Hotel
            Philadelphia, and Live! Casino Pittsburgh. The firm designed several
            amenities, including an event center, poker room, beer garden, and
            sports book for Parx Casino in Bensalem, one of the top-rated
            casinos in Pennsylvania, and the architecture and interior design
            for Parx Casino in Shippensburg, also in Pennsylvania. Currently
            under construction, the Shippensburg casino is due to open this
            winter.
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={EastMarket}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669213793/The%20Narrative%202022/BLTa_East_Market_Drone_Chestnut_Walk_1-min_dc0vtx.jpg"
                alt="East Market"
              />
            </LazyLoad>

            <h6>
              BLTa—A Perkins Eastman Studio has done extensive work in downtown
              Philadelphia, including East Market, which spans an entire city
              block. Copyright Philly by Drone/ Courtesy BLTa
            </h6>
          </div>

          <p>
            Rahe especially enjoys the variety of programs involved in gaming
            projects, because they are often integrated with a resort. “Gaming
            is so broad, we get to work on so many different project types,” he
            says, mentioning hotels, spas, ballrooms, meeting spaces, and
            restaurants as examples. “It is not uncommon to have a dozen
            restaurants in these gaming venues,” he says. Non-gaming hotels in
            the BLTa portfolio include the Marriott Philadelphia Downtown,
            Philadelphia Airport Marriott, Philadelphia Loews Hotel, and Canopy
            by Hilton, among others.
          </p>
          <p>
            Michael Prifti, a principal who joined BLTa in 1982, says the firm
            now has many more opportunities and resources to do the things
            they’ve long aspired to do. “From our point of view, we gained
            access and relationships to a tremendous number of capable
            professionals, design professionals, but also folks in the more
            administrative line of work,” Prifti says.
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={OneCity}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669213918/The%20Narrative%202022/BLTa_1401_Arch-One_City_exterior2_ozjf5h.jpg"
                alt="Arch One City"
              />
            </LazyLoad>

            <h6>
              BLTa transformed a historic office building, formerly known as the
              United Gas Improvement Company Building, into One City, a
              multifamily residence. Copyright Jeffrey Totaro/ Courtesy BLTa
            </h6>
          </div>
          <p>
            Perkins Eastman’s Women’s Leadership Initiative (WLI), a program
            Mary-Jean Eastman launched within the firm in 2014, has inspired
            BLTa’s existing Women in Architecture group, modeled after the AIA.
            Engaging with the larger, firm-wide Perkins Eastman WLI group has
            helped the BLTa members expand their network, share lessons learned,
            and make meaningful connections across the firm.
          </p>
          <p>
            Perkins Eastman’s expertise in sustainability exemplifies another
            expanded resource Prifti appreciates. “In a smaller firm, more
            people are generalists. We have at least one person who is
            LEED-accredited on every team, but now our green team liaises with
            Perkins Eastman’s broader sustainability group across multiple
            studios,” he says.
          </p>
          <p>
            Milton Lau, senior associate of BLTa, brings a unique perspective.
            Lau worked for Perkins Eastman in New York City for six years before
            relocating with his family to Philadelphia and accepting a position
            with BLTa. “The big takeaway for me is when I was at Perkins Eastman
            10 years ago, I worked on big, large-scale projects,” Lau says. His
            work took him on trips to India, where he worked on The Indian
            School of Business, in Mohali, and Antara Dehradun, a senior living
            community in Uttarakhand. He also worked on a TV broadcast station
            in Qatar and a master plan for Hanoi, Vietnam. When Lau joined BLTa,
            however, he began focusing on the local vernacular through the
            particulars of local zoning, construction methodologies, material
            supply chains, and market demands. Now that’s he’s back under the
            Perkins Eastman umbrella, Lau has the opportunity to work on
            national and international projects again.
          </p>
          <p>
            “I’ve enjoyed the beauty of working locally and knowing the folks in
            the community, and now I’m enjoying the beauty of being connected to
            Perkins Eastman and its global resources, global clients, and global
            expertise,” Lau says. “All of these are opportunities that broaden
            my perspective while helping me appreciate what’s right under my
            feet.”
          </p>
        </div>
      </div>
      <LazyLoad offset={300}>
        <div className="parallax nyu"></div>
      </LazyLoad>
      <p className="parallax-caption">
        The living green wall in the lobby of New York University’s School of
        Global Health, designed by Kliment Halsband—A Perkins Eastman Studio,
        sets the tone for the building’s healthful interior environment.
        Copyright Ruggero Vanni/Courtesy Kliment Halsband Architects
      </p>
      <div className="section">
        <div className="static">
          <h3>The Sky’s the Limit</h3>
          <p>
            Celebrating its 50th anniversary this year, Kliment Halsband
            Architects (KHA) and its staff of 17 joined Perkins Eastman in the
            K-12 and Colleges and Universities practices. This distinguished
            firm earned the AIA Architecture Firm Award, the highest honor the
            AIA bestows, in 1997. KHA, renowned for its institutional work, has
            designed projects for Columbia, Yale, Brown, and Johns Hopkins among
            many other universities, as well as numerous K-12 private schools in
            New York.
          </p>
          <p>
            Frances Halsband, a founding partner of KHA and a principal of
            Kliment Halsband Architects—A Perkins Eastman Studio, became the
            61st Chancellor of the AIA College of Fellows on December 9, 2022.
            She’s delighted with the people she’s been meeting since joining
            Perkins Eastman and co-locating at 115 Fifth Avenue in New York
            City.
          </p>
          <p>
            “I have met some amazing and wonderful people here, and it has been
            exactly as I had hoped,” Halsband says, offering an example. “We
            have a university client that we have worked with for years, and we
            have a wonderful relationship with them,” Halsband says. “They came
            to us this spring and said, ‘We have this [historic] academic
            building that we want to work with you on, and we know you’d be
            perfect for it, but, of course, we want it to be passive house.’”
            Halsband paused, and thought, “We can do a lot of things, but we
            can’t do that.” After researching a bit, she was introduced to a
            passive house expert in Perkins Eastman’s studio in Washington, DC.
            “Ryan Dirks came with us to the interview, and he was fabulous, and
            the client said, ‘Great, you’re hired.’ But not only that, now that
            we’re working with him, Ryan really is terrific.”
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={LIRR}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1671458585/The%20Narrative%202022/KHA_LIRR_vertical_cropped_no8lvx.jpg"
                alt="Long Island Rail Road at Penn Station"
              />
            </LazyLoad>

            <h6>
              Kliment Halsband Architects designed this glass-enclosed entrance
              structure for the Long Island Rail Road at Penn Station in New
              York City. Copyright Cervin Robinson/ Courtesy Kliment Halsband
              Architects
            </h6>
          </div>
          <p>
            Halsband provides another example of a potentially symbiotic
            relationship.
          </p>
          <p>
            “We’re working on a master plan for a college in St. Petersburg, FL.
            They have an unused piece of land at the edge of the campus. They
            kept saying, ‘We should do something with this land that could
            benefit us, we could monetize it, there must be something,’”
            Halsband recalls. Then, at a meeting in New York, she met Joe
            Hassel, a Perkins Eastman principal and Senior Living practice
            leader. “I said, ‘Joe, what do you know about doing something like
            that in Florida?’ And with that, he had a whole story about how
            colleges reach out and create housing for older alumni, and how the
            alumni become part of the college community.” Halsband again thought
            to herself, “‘This is exactly the kind of person and relationship
            that I’d hoped to find.’ These two examples are very vivid to me as
            exactly what we thought should happen,” Halsband says.
          </p>
          <p>
            Basler cites another example. “Where we had dropped off a little bit
            in the K-12 private school market, which is very hard to get into,
            KHA brought its portfolio and relationships to the private-school
            world in New York City that we didn’t have. They also brought
            relationships to certain universities that we didn’t have,” he says.
            Leveraging the KHA relationships with the larger Perkins Eastman
            platform has already proven successful. While collaborating, the two
            firms have won a project at the University of Massachusetts Amherst,
            and have been shortlisted for a confidential project on an Ivy
            League campus. “We couldn’t have done those projects without them,”
            Basler says.
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Pratt}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669216153/The%20Narrative%202022/KHA_Pratt_Institute_Manhattan_Center_Int_Gallery_1-min_dm8aqj.png"
                alt="The Pratt Institute Manhattan Center Gallery"
              />
            </LazyLoad>

            <h6>
              The Pratt Institute Manhattan Center Gallery is among many
              projects Kliment Halsband Architects has designed for the school.
              Copyright Ruggero Vanni/Courtesy Kliment Halsband Architects
            </h6>
          </div>
          <p>
            “The great opportunity here is that we’re not competing with each
            other,” Halsband says. “We’re different enough in what we know and
            who we know, 12 Features we’re going after jobs together.” She
            notes, “In all of the marketing conversations, we have yet to have a
            single one where somebody has said, ‘We want to go after that, and
            you can’t.’ It’s really the opposite. . . . These are opportunities
            that we never could have taken advantage of on our own.”
          </p>
          <p>
            “KHA is a wonderful group of people who bring a lovely spirit to the
            New York office, and have proven to be good partners to work with—no
            egos, and terrific team players,” Basler says. “There’s a lot more
            potential. It’s still very early with KHA. The few things we’ve done
            together already have been very different than what we’ve ever done,
            and there’s a lot to build off of there.”
          </p>
          <p>
            Halsband looks forward to the future too. “I have a lot of fun
            putting people together to make things happen,” she says. “I feel
            like there are 1,100 people here I’ve yet to meet, but we’ll see
            what we can do.” <strong>N</strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
