import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import the Link component

import PELogo from "./img/PE_Wordmark_01_White_CMYK_futqsb.png";

function NewComponent(props) {
  // const [isActive, setActive] = useState("false");

  // const ToggleClass = () => {
  //   setActive(!isActive);
  // };
  // const navLinks = document.querySelectorAll(".nav-item");
  const [isActive, setActive] = useState("false");

  // const toggleNav = (e) => {
  //   setActive(!isActive);
  // };

  // const [show, setShow] = useState(false);
  // const showDropdown = (e) => {
  //   setShow(!show);
  // };
  // const hideDropdown = (e) => {
  //   setShow(false);
  // };

  return (
    <div className="menu">
      <Navbar variant="dark" expand="lg">
        <Navbar.Brand href="/">
          <img
            src={PELogo}
            // src="https://res.cloudinary.com/nickpug/image/upload/v1668633989/The%20Narrative%202022/PE_Wordmark_01_White_CMYK_futqsb.png"
            className="img-fluid logo-image"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="app-nav text-white navbar-nav">
            <Link to="/" className="nav-item nav-link">
              Cover
            </Link>
            <Link to="/toc" className="nav-item nav-link">
              Table of Contents
            </Link>

            <NavDropdown title="FEATURES" id="features-dropdown">
              <Link className="dropdown-item" to="/the-age-of-collaboration">
                The Age of Collaboration
              </Link>
              <Link className="dropdown-item" to="/the-wharf-at-long-last">
                The Wharf—At Long Last
              </Link>
            </NavDropdown>
            <NavDropdown title="STORIES" id="stories-dropdown">
              <Link className="dropdown-item" to="/quick-on-the-draw">
                Quick on the Draw
              </Link>
              <Link className="dropdown-item" to="/transformational-designs">
                Transformational Designs
              </Link>
              <Link className="dropdown-item" to="/its-showtime">
                It's Showtime
              </Link>
              <Link className="dropdown-item" to="/park&ride">
                Park & Ride
              </Link>

              <Link className="dropdown-item" to="/the-third-act">
                Living Our Best Life: The Third Act
              </Link>
            </NavDropdown>
            <NavDropdown title="INTERVIEWS" id="interviews-dropdown">
              <Link className="dropdown-item" to="/take-five">
                Take Five
              </Link>
            </NavDropdown>
            <NavDropdown title="IN REVIEW" id="review-dropdown">
              <Link
                className="dropdown-item"
                to="/the-annual-excellence-portfolio"
              >
                The Annual Excellence Portfolio
              </Link>
            </NavDropdown>
            <NavDropdown title="PANORAMA" id="panorama-dropdown">
              <Link className="dropdown-item" to="/a-perkins-eastman-city">
                Imagine A Perkins Eastman City
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default NewComponent;
