import { React } from "react";
import { useState } from "react";
import NavBtns from "../components/NavBtns";

//import images
import BG from "../img/transformational-designs/5961_N60_2_haivgf-min.png";
import Atascadero from "../img/transformational-designs/5961_N60_ikw7ys-min.png";
import AtascaderoBefore from "../img/transformational-designs/Pfeiffer_Atascadero_5961_N17_large_Before_y4ebgd-min.jpg";
import Tutt from "../img/transformational-designs/6121_N243_zeppkh-min.png";
import TuttBefore from "../img/transformational-designs/P8GS0Z_mfkfpl-min.jpg";
import USC from "../img/transformational-designs/6149_01_N2_large_tkavun-min.jpg";
import USCBefore from "../img/transformational-designs/6149_01_N17_large_nfo4pw-min.jpg";
import Bourse from "../img/transformational-designs/BLTa_The_Bourse_01_lnnxxb-min.png";
import BourseBefore from "../img/transformational-designs/BLTa_05_The_Bourse_Food_Hall_Before_r8itkf-min.jpg";

function NewComponent(props) {
  const [isActiveAtascadero, setIsActiveAtascadero] = useState(false);
  function handleClickAtascadero(e) {
    e.preventDefault();
    console.log(e);
    console.log(this);
    setIsActiveAtascadero((current) => !current);
  }

  const [isActiveTutt, setIsActiveTutt] = useState(false);
  function handleClickTutt(e) {
    e.preventDefault();
    console.log(e);
    console.log(this);
    setIsActiveTutt((current) => !current);
  }

  const [isActiveSoCal, setIsActiveSoCal] = useState(false);
  function handleClickSoCal(e) {
    e.preventDefault();
    console.log(e);
    console.log(this);
    setIsActiveSoCal((current) => !current);
  }

  const [isActiveBourse, setIsActiveBourse] = useState(false);
  function handleClickBourse(e) {
    e.preventDefault();
    console.log(e);
    console.log(this);
    setIsActiveBourse((current) => !current);
  }
  return (
    <div className="page page-container transformational-designs">
      <NavBtns prev="/quick-on-the-draw" next="/its-showtime" />
      <div className="hero-wrapper">
        <div className="hero ">
          <div className="hero-images">
            <img
              id="hero-bg"
              src={BG}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1671132092/The%20Narrative%202022/5961_N60_2_haivgf.png"
              alt="Atascadero City Hall"
            />
          </div>
          <div className="hero-text">
            <h2>Transformational</h2>
            <h1>Designs</h1>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="static ">
          <h6 className="by-line">by Trish Donnally</h6>
          <h6>
            Reimagining our existing building stock is key to reducing the
            country’s carbon footprint.
          </h6>
          <p className="caption">
            Renovations have surpassed new construction in the US for the first
            time in the 20 years since the American Institute of Architects
            (AIA) began tracking renovation, retrofitting, restoration, and
            reconstruction. A recent Bloomberg story calls the trend “One
            Nation, Under Renovation.” According to an AIA report from last May,
            “Renovation claims 50 percent share of firm billings for the first
            time.”
          </p>
          <div className="before-after__wrapper">
            <div
              className={
                isActiveAtascadero ? "active before-after" : "before-after"
              }
            >
              <div className="after">
                <img
                  // id="hero-bg"
                  src={Atascadero}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669656595/The%20Narrative%202022/5961_N60_ikw7ys.png"
                  alt="Atascadero City Hall"
                />
              </div>
              <div className="before">
                <img
                  src={AtascaderoBefore}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136648/The%20Narrative%202022/Pfeiffer_Atascadero_5961_N17_large_Before_y4ebgd.jpg"
                  alt="Atascadero City Hall"
                />
                <button className="before-btn" onClick={handleClickAtascadero}>
                  <div>
                    <span>
                      {isActiveAtascadero ? "Close X" : "See Original"}
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <h6 className="image-captions">
            Atascadero City Hall In 2003, after sustaining severe damage during
            the 6.5-magnitude San Simeon earthquake, historic Atascadero City
            Hall in Atascadero, CA, built in 1918, was in dire need of repair.
            With a shared goal of rehabilitating while repairing, Pfeiffer—A
            Perkins Eastman Studio embraced many of the historic elements while
            simultaneously updating the space. The design team repaired and
            rehabilitated the structure, reprogrammed the building, created a
            museum, and transformed the first-floor rotunda. Surprisingly, the
            rotunda’s original interiors were all white. “They didn’t paint
            anything inside; they just left it. Whether that’s because they ran
            out of money or whatever, we don’t know,” says Stephanie Kingsnorth,
            a principal at Pfeiffer. The design team developed a new color
            palette for the inside of the building, transformed the rotunda into
            a glorious public gathering space, and restored Atascadero City Hall
            as the centerpiece of the community. The exquisite building is on
            the National Register of Historic Places and is a California
            Registered Historical Landmark. After Photo Copyright Tim
            Griffith/Courtesy Pfeiffer. Before Photo Courtesy Atascadero
            Historical Society
          </h6>
        </div>
      </div>

      <div className="section">
        <div className="static">
          <p>
            “I think we are, as a country and as design construction industries,
            finding that the opportunities to adapt or renovate what we already
            have is at least as appealing as starting anew,” AIA’s Chief
            Economist, Kermit Baker, says in a recent report. This perspective
            represents a sea change from the reaction the late architect and
            sustainability champion Lance Hosey received when he wrote “Stop
            Building. Now.” for HuffPost in 2013. Hosey’s proposal to cease all
            new construction in the US drew a wave of criticism within the
            architecture industry.
          </p>
          <p>
            It was also prescient—at least in spirit. “As his article lays out,
            we have an overabundance of vacant buildings, and Lance suggested
            that if a new-construction moratorium were enforced, we’d be forced
            to look deeply at the existing building stock and seriously question
            what we need and how we could get there,” says Perkins Eastman’s
            Director of Sustainability Heather Jauregui, who is based in
            Washington, DC.
          </p>
          <div className="before-after__wrapper">
            <div
              className={isActiveTutt ? "active before-after" : "before-after"}
            >
              <div className="after">
                <img
                  src={Tutt}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669656987/The%20Narrative%202022/6121_N243_zeppkh.png"
                  alt="Charles L. Tutt Library at Colorado College"
                />
              </div>
              <div className="before">
                <img
                  src={TuttBefore}
                  // src="https://res.cloudinary.com/nickpug/image/upload/e_grayscale/v1669657098/The%20Narrative%202022/P8GS0Z_mfkfpl.jpg"
                  alt="Charles L. Tutt Library at Colorado College before"
                />
                <button className="before-btn" onClick={handleClickTutt}>
                  <div>
                    <span>{isActiveTutt ? "Close X" : "See Original"}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <h6>
            Charles L. Tutt Library at Colorado College While renovating the
            main library at this liberal-arts college in Colorado Springs, CO,
            the Pfeiffer team combined preservation, rehabilitation, and new
            construction while simultaneously achieving net zero energy (NZE).
            This made it the first academic 24-7 library to achieve NZE in the
            nation. The team preserved certain elements and the intent of the
            original architect, Walter Netsch, who designed the library in 1961.
            “We treated him as a partner, but we drastically modernized the
            interior environment while keeping most of the exterior,” Kingsnorth
            says. “The concrete on that building was gorgeous. It had a waffle
            slab and the exposed coffers were just beautiful. It was some of the
            crispest, cleanest concrete we’ve ever seen. But then everything
            that was overlaid on top of it was 100 percent modern,” Kingsnorth
            says. For the exterior, essentially a concrete box, Pfeiffer removed
            some of the pre-cast panels and opened the building up to daylight
            and views of the Rocky Mountains. “And, we put an addition on it
            that literally wrapped the original building like a ribbon. That
            harmonized the new and the old.” After Photo Copyright Steve
            Lerum/Courtesy Pfeiffer. Before Photo Copyright Stephanie Kingsnorth
          </h6>
          <h3>The New Frontier</h3>
          <p>
            Currently, buildings are responsible for about 40 percent of the
            world’s fossil-fuel carbon-dioxide emissions (CO2). “But that number
            can be greatly reduced by limiting the embodied carbon of our
            building materials. Embodied carbon—the CO2 released during material
            extraction, manufacture, and transport, combined with construction
            emissions—will be responsible for 74 percent of all CO2 emissions of
            new buildings in the next 10 years. And unlike operational carbon,
            which can be reduced during a building’s lifetime, embodied carbon
            is locked in as soon as a building is completed and can never be
            decreased,” according to the AIA.
          </p>
          <p>
            Embodied carbon reduction is the new frontier in green building.
          </p>{" "}
          <p>
            “It’s the elephant in the room that we know relatively little about,
            compared to operational carbon, and research and tools are being
            developed in droves to help architects across the world be able to
            understand and tackle the embodied carbon challenge in their work,”
            Jauregui says.
          </p>
          <p>
            “The embodied carbon conversation led to an increased focus on the
            reuse of existing buildings, because the carbon that we spend
            now—embodied—is going to determine the extent of global warming,”
            Jauregui says in reference to the 2015 Paris Agreement on global
            warming. “We still have a long way to go.”
          </p>{" "}
          <p>
            Shattering the misconception that existing buildings can’t achieve
            high levels of efficiency is one challenge. New legislation,
            however, is spurring action. New York City passed the Climate
            Mobilization Act in 2019, which requires owners of structures 25,000
            sf or larger to reduce emissions or pay a substantial fine. The
            legislation’s goal is to reduce carbon output by 40 percent.
            Currently, according to the AIA, nearly 70 percent of New York
            City’s emissions come from buildings. The District of Columbia’s
            Building Energy Performance Standards (BEPS) Program, designed to
            reduce emissions and energy consumption by 50 percent by 2032, is
            beginning to force existing buildings to meet thresholds of
            performance too. “Climate-related legislation will both drive down
            operational carbon, and then increase the amount of existing
            building upgrades that are required to maintain compliance, and thus
            increase the market for building reuse,” Jauregui says.
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="before-after__wrapper">
            <div
              className={isActiveSoCal ? "active before-after" : "before-after"}
            >
              <div className="after">
                <img
                  src={USC}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669657552/The%20Narrative%202022/6149_01_N2_large_tkavun.jpg"
                  alt="the new home for the School
            of Dramatic Arts"
                />
              </div>
              <div className="before">
                <img
                  src={USCBefore}
                  // src="https://res.cloudinary.com/nickpug/image/upload/e_grayscale/v1669657557/The%20Narrative%202022/6149_01_N17_large_nfo4pw.jpg"
                  alt="a church on the boundary of the University of
            Southern California’s (USC) campus"
                />
                <button className="before-btn" onClick={handleClickSoCal}>
                  <div>
                    <span>{isActiveSoCal ? "Close X" : "See Original"}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <h6>
            Pfeiffer is converting a church on the boundary of the University of
            Southern California’s (USC) campus into the new home for the School
            of Dramatic Arts. “That one is going to be a wonderful mix of
            uber-historic elements and modern insertions. It’s part of a
            National Registered District, so we have to be very cognizant of the
            exterior of the building,” Kingsnorth says. “As you walk inside the
            building there are character-defining features such as stamped
            concrete floors, stained glass windows, and dramatic wood trusses in
            the chapel. But as soon as you move away from those spaces, the
            modern insertions begin.” Modern light fixtures designed with colors
            that reference the original stainedglass windows are one example.
            “Those very bold, strong colors of the primary family are going into
            modern fixtures,” Kingsnorth notes. Their hues relate back to the
            historic fabric of the building while their shapes and materials
            look forward. After Photo Copyright Krai Charuwatsunton, Before
            Photo Copyright Stephanie Kingsnorth
          </h6>
          <h3>Embodied Carbon and Character</h3>{" "}
          <p>
            Accepting the premise that any building can be transformed is
            another issue. The buildings that “everyone wants to demolish, that
            appear to have the largest problems, still have value—both in terms
            of embodied carbon but also in terms of community history and
            context that’s worthy of trying to preserve. Yes, it may be more
            challenging than going to new construction, but it can also lead to
            much more rewarding results,” Jauregui contends.
          </p>{" "}
          <p>
            Los Angeles-based Stephanie Kingsnorth, a principal of Pfeiffer—A
            Perkins Eastman Studio, concurs. “If you simply save the exterior
            envelope of a building in the primary structure, you are reducing
            your carbon footprint by essentially 50 percent,” she says. “We
            can’t go around tearing everything down. We need to focus on energy
            utilization, embodied carbon, all of these things.”
          </p>
          <p>
            And the decision, she believes, should not be based on aesthetics.
            “Some people will say, ‘Oh, I hate midcentury buildings, or I hate
            brutalism, … oh, it’s just so ugly, it looks like a concrete box.
            That doesn’t matter, you can change it. Again, the embodied carbon
            that’s in that concrete is something that really needs to be
            carefully considered before you just take all that down.” Before
            tearing down a building, Kingsnorth notes, developers and their
            architects should determine what percentage of material is
            recyclable, and how much of it will have to go to a landfill.
          </p>
          <p>
            “Take aesthetics out [of the equation] because you can always modify
            the aesthetics, unless it’s a registered historic building,” says
            Kingsnorth. “There, you’ve got more ropes that you have to untie and
            retie. But when you’re dealing with something that is mid-century or
            more of the recent past, there’s a lot you can do with it.”
          </p>
          <p>
            When weighing whether to demolish or renovate a building, cost is
            often the deciding factor because clients look at cost per square
            foot and realize they could tear down a structure and build new for
            roughly the same price. Another factor is that some clients aren’t
            able to visualize the transformation potential in a decrepit
            building or the return on investment. It takes “feasibility studies,
            concept studies, and renderings, and putting together the ‘before’
            and ‘after’ imagery to make it click and have it actually, visually
            get into the brain.
          </p>
          <p>
            “And then they start to feel it, they start to live it, it becomes
            almost like a child to them . . . they’re fostering the growth of
            what the building can become,” Kingsnorth says, adding that
            architects must eschew the negativity sometimes associated with old
            buildings. “We need to consider them from an aesthetic,
            sustainability, global, environmental, and placemaking point of
            view, and embrace those aspects.” No matter the era they are,
            Kingsnorth says, “You can make existing buildings sing.”
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="before-after__wrapper">
            <div
              className={
                isActiveBourse ? "active before-after" : "before-after"
              }
            >
              <div className="after">
                <img
                  src={Bourse}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669657819/The%20Narrative%202022/BLTa_The_Bourse_01_lnnxxb.png"
                  alt="The Bourse"
                />
              </div>
              <div className="before">
                <img
                  src={BourseBefore}
                  // src="https://res.cloudinary.com/nickpug/image/upload/e_grayscale/v1669136490/The%20Narrative%202022/BLTa_05_The_Bourse_Food_Hall_Before_r8itkf.jpg"
                  alt="The Bourse before"
                />
                <button className="before-btn" onClick={handleClickBourse}>
                  <div>
                    <span>{isActiveBourse ? "Close X" : "See Original"}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <h6>
            The Bourse BLTa—A Perkins Eastman Studio transformed The Bourse in
            Philadelphia, which was originally built in 1895 as the first
            Commodities Exchange Hall in the US. Over the years, The Bourse was
            converted to offices, retail, and some food spaces, but faded from
            its former glory. As the BLTa design team renovated the space, they
            discovered, embraced, and integrated long-forgotten architectural
            details, which punctuate its transformation into a magnificent
            light-filled artisanal food hall, plus nine floors of offices.
            Before Photo Courtesy BLTa. After Photo Copyright Jeffrey
            Totaro/Courtesy BLTa
          </h6>
          <h3>Renovating the Urban Core</h3>
          <p>
            By 2050, more than two-thirds of the world’s population is projected
            to be living in urban areas, according to a 2017 UN Human
            Development Report, so the reuse of buildings in the urban core will
            be especially critical. Philadelphia is among the many cities with
            an abundance of buildings ripe for renovation. “The footprint has
            expanded to adjoining neighborhoods, and Philadelphia has tons of
            stock yet to be renovated,” says Michael Prifti, a principal with
            BLTa—A Perkins Eastman Studio, who has been rehabilitating,
            restoring, preserving, and adaptively reusing projects in the City
            of Brotherly Love for 40 years.
          </p>
          <p>
            Applying for historic tax credits has proven to be advantageous in
            many cases. “Clients can reap a 20 percent tax credit, taken against
            much of the project costs. Philadelphia, Chicago, and almost every
            major city celebrates numerous successful reuse gems, if a developer
            is willing to go through this process,” Prifti says. The regulatory
            requirements can be long and complex—involving a multilevel process,
            each level as many as 45 days in length, barring any repeated review
            cycles. Some clients prefer to save themselves time by not pursuing
            tax credits. “But the urban environment truly benefits from savvy
            reuse,” Prifti says.
          </p>
          <p>
            The process is different in each jurisdiction but begins in the same
            way. “You have to make a case that the building is significant
            through the National Park Service (NPS) Part I forms. Next, your
            design is summarized through the NPS Part II forms, then reviewed by
            several regulatory bodies. In Philadelphia, it’s a four-level
            process: the Philadelphia Architectural Review Committee; the
            Philadelphia Historical Commission; the Pennsylvania State Historic
            Preservation Office; and finally, the National Park Service,” Prifti
            explains.
          </p>{" "}
          <p>
            This sounds like a lot of work. Prifti adds, however, “The
            temptation of the 20 percent tax credits is almost irresistible.
            Given the availability and the significance of properties in
            Philadelphia and throughout the northeast and mid-Atlantic portions
            of the US, it is a rare event when a client does not pursue historic
            tax credits.”
          </p>{" "}
          <p>
            Kingsnorth strongly advocates transforming the existing built
            environment, just as Lance Hosey did years ago. “That’s what makes
            our built environment so amazing. It’s this wonderful blend of new
            and old,” she says. “The communities, the downtowns, the areas that
            we all appreciate are a mix, they’re never all new. You want that
            harmonization of current day coming into the past and making a
            vibrant future.” <strong>N</strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
