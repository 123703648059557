import { React } from "react";
// import { Link } from "react-router-dom"; // Import the Link component
// import { motion } from "framer-motion";
// import Confetti from "./components/confetti.js";
import NavBtns from "./components/NavBtns";
import LazyLoad from "react-lazy-load";
// import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGithubSquare } from "@fortawesome/free-brands-svg-icons";

import { faLink } from "@fortawesome/free-solid-svg-icons";
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'

//images import
import Cover from "./img/Banneker_cover_revised_Sarah3_bjtb3t.png";
import NarrativeLogo from "./img/The_Narrative_logo-white_w_blue_rsleb3.png";
import PELogo from "./img/PE_Wordmark_01_White_CMYK_futqsb.png";
import Wharf from "./img/the_wharf_lkvc99.jpg";
import Everton from "./img/Meis-Everton_adjused_nb7ymt.jpg";
import Banneker from "./img/80300_00_J_N42_mr_g3lj2m.jpg";

function NewComponent(props) {
  // useEffect(() => {
  //   const domain = window.location.host;
  //   const $meta = document.createElement("meta");
  //   document.head.appendChild($meta);
  //   $meta.setAttribute("property", "og:image");
  //   $meta.setAttribute("content", domain + "/img/narrative-for-social-web.jpg");
  //   // $meta.innerHTML = `<meta property="og:image" content="/img/narrative-for-social-web.jpg"/>`;
  // }, []);

  return (
    <div className="page page-container home content-wrapper">
      {/* <Helmet>
        <meta property="og:image" content="/img/narrative-for-social-web.jpg" />
      </Helmet> */}
      <NavBtns next="/toc" />
      {/* <Confetti /> */}
      <div className="cover">
        <img
          // src="https://res.cloudinary.com/nickpug/image/upload/v1670527231/The%20Narrative%202022/Banneker_cover_revised_Sarah3_bjtb3t.png"
          src={Cover}
          className="img-fluid"
          alt="The Narrative cover"
        />

        {/* <div id="cover-item">
          <img
            src="https://res.cloudinary.com/nickpug/image/upload/v1668630532/The%20Narrative%202022/Layer_7_copy_hboxi6.png"
            className="img-fluid"
            alt="The Narrative cover"
          />
        </div> */}
        <div className="text">
          <p className="highlight">The Third Act</p>
          <p className="highlight">The age of collaboration</p>
          <p className="highlight">quick on the draw</p>
        </div>
        <div className="title">
          <img
            src={NarrativeLogo}
            // src="https://res.cloudinary.com/nickpug/image/upload/v1671121148/The%20Narrative%202022/The_Narrative_logo-white_w_blue_rsleb3.png"
            alt="The N"
          />
        </div>
        <div className="issue-wrapper">
          <p className="issue">2022</p>
          <img
            className="logo"
            src={PELogo}
            // src="https://res.cloudinary.com/nickpug/image/upload/v1668633989/The%20Narrative%202022/PE_Wordmark_01_White_CMYK_futqsb.png"
            alt="Perkins Eastman"
          />
        </div>
      </div>
      <div className="welcome">
        <div className="left">
          <h1>Welcome</h1>
          <p>
            The Fall 2022 issue of <i>The Narrative</i> captures compelling
            stories about Perkins Eastman people and projects around the world.
          </p>
          <p>
            Perkins Eastman welcomed five firms into the fold over the last few
            years, spurring creative and strategic synergies with intriguing new
            colleagues. Our combined efforts are proving serendipitous in myriad
            ways.{" "}
            <a href="/the-age-of-collaboration">
              <FontAwesomeIcon icon={faLink} className="fontawesome" />
            </a>{" "}
            In another story of collaborative endeavor, we mark the opening of
            the second and final phase of The Wharf in southwest Washington, DC,
            with personal anecdotes from our 16-year journey as the master
            architect of this vibrant new waterfront neighborhood, which has
            developed into an international destination.{" "}
            <a href="/the-wharf-at-long-last">
              <FontAwesomeIcon icon={faLink} className="fontawesome" />
            </a>{" "}
          </p>
          <p>
            In a striking study, the American Institute of Architects reveals
            that renovations have surpassed new construction throughout the
            nation for the first time in the 20 years the institute has been
            tracking this trend. This focus on existing buildings holds
            tremendous potential for curbing climate change.{" "}
            <a href="/transformational-designs">
              <FontAwesomeIcon icon={faLink} className="fontawesome" />
            </a>{" "}
          </p>
          <p>
            If you’ve noticed fewer architects sketching by hand, relying
            instead on digital tools, we have a refreshing story about Perkins
            Eastman artists who enjoy hand sketching so much they do it on and
            off the job.{" "}
            <a href="/quick-on-the-draw">
              <FontAwesomeIcon icon={faLink} className="fontawesome" />
            </a>{" "}
            Performing arts venues integrate and enliven patrons’ experiences in
            new ways. Read about the Shakespeare-inspired theater created from
            shipping containers, a beachfront amphitheater enhanced with VIP
            amenities, and a multipurpose theater on a dramatic campus site.{" "}
            <a href="/its-showtime">
              <FontAwesomeIcon icon={faLink} className="fontawesome" />
            </a>{" "}
            In terms of transit-oriented development, Perkins Eastman and our
            specialty studios are helping to catalyze a resurgence in downtown
            Raleigh, making Philadelphia’s century-old subway system accessible,
            and extending the SkyTrain in Canada.{" "}
            <a href="/park&ride">
              <FontAwesomeIcon icon={faLink} className="fontawesome" />
            </a>{" "}
          </p>
          <p>
            Six PEople from around the firm share their thoughts on
            architecture, inclusivity, mentorship, and more.{" "}
            <a href="/take-five">
              <FontAwesomeIcon icon={faLink} className="fontawesome" />
            </a>{" "}
            Inclusiveness is also a theme in the story about new senior living
            communities that represent seismic shifts as developers tailor
            housing to residents’ lifestyles. Feeling “understood and valued” is
            key.{" "}
            <a href="/the-third-act">
              <FontAwesomeIcon icon={faLink} className="fontawesome" />
            </a>{" "}
          </p>
          <p>
            The Annual Excellence Portfolio, an inspiring celebration of Perkins
            Eastman’s talented staff members and the firm’s most outstanding
            projects of 2021, beautifully reflects our Human by Design ethos.{" "}
            <a href="/the-annual-excellence-portfolio">
              <FontAwesomeIcon icon={faLink} className="fontawesome" />
            </a>{" "}
            Our people-focused design thinking is also found in “Imagine a
            Perkins Eastman City,” a fictitious metropolis populated with
            projects from some of our 18 practice areas. Its skyline was a pure
            pleasure to create, and we hope it will be fun to explore.
            <a href="/a-perkins-eastman-city">
              <FontAwesomeIcon icon={faLink} className="fontawesome" />
            </a>
          </p>
          <h6>The Communications Team</h6>
          <br />
          <br />

          <h4>Editor in Chief</h4>
          <h5>Trish Donnally</h5>

          <h4>Executive Director</h4>
          <h5>Abby Bussel</h5>

          <h4>Contributors</h4>
          <h5>
            Emily Bamford, Jennica Deely, Nick Leahy, Jennifer Sergent,
            Ling&nbsp;Zhong
          </h5>

          <h4>Graphic Design Editor</h4>
          <h5>Kim Rader</h5>

          <h6>LET US HEAR FROM YOU</h6>
          <p>
            Please send questions, comments, or a story to share to:{" "}
            <a href="mailtto:humanbydesign@perkinseastman.com">
              humanbydesign@perkinseastman.com
            </a>
          </p>
          <p>© 2022 Perkins Eastman. All Rights Reserved.</p>
        </div>
        <div className="right">
          <div className="single-image">
            <LazyLoad>
              <img
                src={Wharf}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1668707264/The%20Narrative%202022/the_wharf_lkvc99.jpg"
                alt="The Wharf, DC"
              />
            </LazyLoad>

            <p className="image-caption">
              View of The Wharf Phase 2, which opened on October 12, 2022
            </p>
            <p className="copyright">
              Copyright Matthew Borkoski/Courtesy Hoffman-Madison Waterfront
            </p>
          </div>
          <div className="single-image">
            <LazyLoad>
              <img
                src={Everton}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136627/The%20Narrative%202022/Meis-Everton_adjused_nb7ymt.jpg"
                alt="Everton sketch"
              />
            </LazyLoad>

            <p className="image-caption">Everton Stadium sketch by Dan Meis</p>
            <p className="copyright">Photograph Courtesy Dan Meis </p>
          </div>
          <div className="single-image">
            <LazyLoad>
              <img
                src={Banneker}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669843127/The%20Narrative%202022/80300_00_J_N42_mr_g3lj2m.jpg"
                alt="lobby"
              />
            </LazyLoad>

            <p className="image-caption">
              Benjamin Banneker Academic High School Learning Commons Atrium
            </p>
            <p className="copyright">
              Copyright Joseph Romeo/Courtesy Perkins Eastman
            </p>
          </div>
          <p className="cover-credits">
            Cover design by Kim Rader based on the original submission by the
            artists, Christian Benefiel and Ryan McKibbin, who created the clock
            sculpture <i>Time and Space</i>, which hangs in the atrium of
            Benjamin Banneker Academic High School in Washington, DC (above).
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
