import { React } from "react";
import NavBtns from "../components/NavBtns";
import LazyLoad from "react-lazy-load";

//import images
import BG from "../img/its-showtime/6131_00_Gonzaga_N10-min.jpg";
import GlobeNight from "../img/its-showtime/IMG_4216-min.png";
import Simon from "../img/its-showtime/IMG_4336_pdfdtj-min.jpg";
import GlobeDay from "../img/its-showtime/7-Birds-Eye-Foggy_Golden_k0nvsj-min.jpg";
import MyrtleWoldson from "../img/its-showtime/6131_00_Gonzaga_N26_large_g9b3oi-min.jpg";
import Rehearsal from "../img/its-showtime/6131_00_Gonzaga_N21-min.jpg";
import Beach from "../img/its-showtime/Jones_Beach_1_mr98ds-min.jpg";

function NewComponent(props) {
  return (
    <div className="page page-container its-showtime">
      <NavBtns prev="/transformational-designs" next="/park&ride" />
      <div className="hero-wrapper">
        <div className="hero">
          <div className="hero-images">
            <img
              id="hero-bg"
              src={BG}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1668630400/The%20Narrative%202022/6131_00_Gonzaga_N10_large_o41wfq.jpg"
              alt="The Myrtle Woldson Performing Arts Center"
            />
          </div>
          <div className="hero-text">
            <h2>It's</h2>
            <h1>Showtime</h1>
          </div>
        </div>
      </div>
      <h5>
        The Myrtle Woldson Performing Arts Center, designed by Pfeiffer—A
        Perkins Eastman Studio, establishes a dramatic new front door on the
        western edge of the Gonzaga University campus. Copyright Ema
        Peter/Courtesy Pfeiffer
      </h5>

      <div className="section">
        <div className="static">
          <p className="caption">
            From a punk-rock-inspired Globe theater to VIP-driven outdoor
            amphitheaters, performing arts venues are becoming as immersive as
            the performances they support.
          </p>
          <h6 className="by-line">by Jennica Deely</h6>
          <p className="cap">
            Part of the magnetism of live performance is its impermanence. As
            the curtain rises and falls, theater actors create worlds that
            crystallize and dissipate. With the lifting of a bow, the wetting of
            a reed, or the tapping of a finger, musicians generate sounds that
            build and fade. With every pivot, leap, and turn, dancers make
            kinetic sculptures that form and unfold.
          </p>
          <p>
            To support the singular act of performance—and the burst of energy
            and creativity it requires—performing arts venues must provide an
            agile and immersive framework for artist and audience alike. Take
            The Container Globe, for instance. Planned for a site in Detroit and
            designed by Perkins Eastman, this venue is assembled from shipping
            containers to harness the raw energy of Shakespeare productions and
            more contemporary fare—minimal stage dressing for maximum theatrical
            impact. The Myrtle Woldson Performing Arts Center at Gonzaga
            University in Spokane, WA, designed by Pfeiffer—A Perkins Eastman
            Studio, optimizes practice and performance spaces as it harmonizes
            with its historic campus setting. And to deliver mass appeal, the
            overhaul of Live Nation’s 40-plus outdoor amphitheaters, a project
            led by MEIS—A Perkins Eastman Studio, leverages emerging
            technologies to reimagine the spectacle of live shows.
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={GlobeNight}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136596/The%20Narrative%202022/IMG_4216_qklrxi.png"
                alt="The Container Globe"
              />
            </LazyLoad>

            <h6>
              The Container Globe in Detroit, assembled from repurposed shipping
              containers, is a modern interpretation of Shakespeare’s iconic
              Globe Theatre with proportions remarkably similar to those of the
              original—and a potential prototype for Container Globes in other
              cities. Copyright Ron Castellano/Courtesy Perkins Eastman
            </h6>
          </div>
          <h3>Moving Parts: The Container Globe</h3>
          <p>
            In 1598, an unscrupulous landlord inadvertently spurred the creation
            of Shakespeare’s legendary Globe Theatre. With the lease of the land
            its theater occupied soon to expire, The Lord Chamberlain’s Men, the
            theater company co-founded by William Shakespeare, requested a
            renewal. But landlord Giles Allen refused to comply. His scheme?
            Take ownership of all structures located on his land, including the
            theater building.
          </p>
          <p>
            In the dark of night on Christmas Eve, while Allen was out of town,
            the company swiftly disassembled the massive oak timbers of the
            theater and transferred them to warehouses in London. When a new
            site was secured, the materials were reassembled, and the Globe
            Theatre was born in the summer of 1599. This dramatic story is, in
            part, the inspiration behind The Container Globe theater in Detroit.
          </p>
          <p>
            Conceived by Angus Vail with assistance from Perkins Eastman, Arup,
            Michael Ludvik Engineering, community members, and Shakespeare
            aficionados, this project employs shipping containers in place of
            the oak timbers, plaster, and thatched roof of the original
            building. Shipping containers have proven to be an excellent
            substitute for those age-old materials: assembled into a 10-sided
            metal “globe,” the proportions of the structure are remarkably
            similar to those of the original Globe. Envisioned as a prototype
            for Container Globes in other cities, its simple materials and
            relative ease of assembly and disassembly will bring high-impact
            arts into communities at a relatively low cost. “The whole point of
            this is it’s a movable theater; if we need to, we can break it down
            and move it,” says Vail. With 30 containers, seating for 870, and
            standing room for 700, he clarifies, the theater is “movable but not
            mobile.”
          </p>
          <LazyLoad offset={300}>
            <img
              src={Simon}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1668630477/The%20Narrative%202022/IMG_4336_pdfdtj.jpg"
              alt="Curtis Roach"
            />
          </LazyLoad>{" "}
          <h6>
            A private event held this past summer to celebrate progress on The
            Container Globe featured Detroit rap artist Curtis Roach. The Motor
            City Youth Theater and world-renowned opera singer Simon O’Neill
            also performed, and, fittingly, several Shakespeare scenes were
            presented. Copyright Ron Castellano/Courtesy Perkins Eastman
          </h6>
          <p>
            Located in the Virginia Park neighborhood, near the site of the 1967
            civil rights riots and just a half mile from the original site of
            Motown Records, The Container Globe’s aesthetic complements
            Detroit’s industrial past and reflects the city’s regenerative
            future. To construct the seating galleries that wrap in a semicircle
            around the stage, 20-foot-long containers are stacked three high
            (nine stacks in all). The stage and backstage areas are formed by
            40-foot-long containers.
          </p>
          <p>
            Devising a demountable foundation and securing connections between
            the containers have proven to be among the more challenging aspects
            of the project. A poured-in-place foundation and welded connections
            would be counter to the project’s concept, so the design team is
            exploring materials such as railroad ties, crane cribbing, and
            ground screws as well as reuse of the shipping containers’ original
            interlocks. Prototypes that meet city safety standards for
            performance venues are in development.
          </p>
          <p>
            Vail was drawn to Shakespeare’s work through the raw enthusiasm
            exhibited by the audience at Shakespeare’s Globe, an approximate
            replica of the Globe Theatre located near the site of the original
            building in Shoreditch, then just beyond London’s city limits. “With
            most theaters, the cheapest tickets are at the back. At the Globe,
            the cheapest tickets—for the ‘groundlings’—have always been closest
            to the stage, and many of the groundlings are young people who bring
            a lot of energy to the space. In that way it reminds me of the
            positive energy at punk rock shows,” he says.
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={GlobeDay}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136458/The%20Narrative%202022/7-Birds-Eye-Foggy_Golden_k0nvsj.jpg"
                alt="The Container Globe"
              />
            </LazyLoad>

            <h6>
              The flexibility and simplicity of The Container Globe provides an
              ideal framework for Shakespeare plays as well as other live
              theater, music, and cultural events. Rendering Copyright Perkins
              Eastman
            </h6>
          </div>
          <p>
            A self-described punk, Vail grew up in New Zealand and Great Britain
            in the 1970s and ’80s listening to the music of the Sex Pistols, the
            Clash, and the Ramones. He found a kindred spirit in another punk
            fan, Nick Leahy, co-CEO and executive director of Perkins Eastman.
            Vail shared his idea for the Detroit project after meeting Leahy at
            an American Institute of Architects conference eight years ago.
            Unbeknownst to Vail, Leahy had worked on the initial planning
            submission of the London Globe reproduction with Theo Crosby at
            Pentagram in the 1980s. “I thought it was a weird coincidence to run
            into this guy at a conference in New York nearly 40 years later,”
            Leahy recalls. He was intrigued but skeptical. “I thought, ‘Not
            another shipping container project.’” But he changed his mind on
            hearing about Vail’s concept for a modern-day interpretation of the
            Globe that would present high-energy, pared-down performances of
            Shakespeare’s work. “We share this idea that Shakespeare was the
            punk of his day,” says Leahy. His work can appear formulaic and
            bawdy at first, but on closer inspection, the multiple layers of
            Shakespeare’s plays are revealed—characteristics similar to the
            five-chord progressions, provocative lyrics, and fast pace of many
            punk anthems. “His plays have remained successful because they
            appeal on so many levels,” says Leahy.
          </p>
          <p>
            When it opens in spring of 2023, The Container Globe will be a venue
            for Shakespeare’s plays as well as other performances, from dance
            and rap to opera and bluegrass, says Vail. He hopes it will inspire
            Shakespeare lovers to expand their tastes and fans of other genres
            to give the Bard a chance. “I want people to come in and see a rap
            battle,” says Vail, “and love the Globe space, and maybe come back
            to see some great Shakespeare and make that connection between
            hip-hop poetry and Shakespeare’s rhymes and rhythms.”
          </p>
          <div className="images-single">
            {" "}
            <LazyLoad offset={300}>
              <img
                src={MyrtleWoldson}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1668630406/The%20Narrative%202022/6131_00_Gonzaga_N26_large_g9b3oi.jpg"
                alt="The Myrtle Woldson Performing Arts Center"
              />
            </LazyLoad>
            <h6>
              The Myrtle Woldson Performing Arts Center features an elegant and
              understated 750‑seat multipurpose theater; its design is inspired
              by the basalt outcroppings, stratified riverbanks, and exposed
              fractured rock of the Spokane River, which the building overlooks.
              Copyright Ema Peter/Courtesy Pfeiffer
            </h6>
          </div>
          <h3>
            Campus Sights and Sounds: Myrtle Woldson Performing Arts Center
          </h3>
          <p>
            The Myrtle Woldson Performing Arts Center at Gonzaga University is
            perched above the Spokane River. Its brick-and-glass exterior speaks
            to both the historic, Collegiate Gothic brick buildings and
            contemporary architecture found at Gonzaga, while its interiors—a
            750-seat multipurpose theater, 160-seat rehearsal hall, gallery, and
            studio spaces—act like finely tuned instruments to support
            performances, rehearsals, and myriad related activities.
          </p>
          <p>
            Sited on the western edge of Gonzaga University, the 52,000-sf
            building takes advantage of the stunning river views and sets the
            tone for future campus development. As with Gonzaga, says William
            Murray, a principal and leader of the Performing Arts practice at
            Pfeiffer, performing arts projects “tend to become front door campus
            buildings because they’re often located on the edges of campus.” The
            ample daylight and framed river views in the lobby, studios, and
            gallery spaces connect performers and visitors to their
            surroundings, and the intersecting volumes of the building and
            richly detailed materials of the performance and rehearsal spaces
            echo the basalt outcroppings, stratified banks, and fractured rock
            of the nearby river.
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="images-single">
            {" "}
            <LazyLoad offset={300}>
              <img
                src={Rehearsal}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1668630405/The%20Narrative%202022/6131_00_Gonzaga_N21_large_qes86z.jpg"
                alt=" The rehearsal hall at the Myrtle Woldson Performing Arts Center"
              />
            </LazyLoad>
            <h6>
              The rehearsal hall at the Myrtle Woldson Performing Arts Center
              features expansive campus views and is a flexible space that can
              support performance, rehearsal, and instruction for a variety of
              disciplines. Copyright Ema Peter/Courtesy Pfeiffer
            </h6>
          </div>
          <p>
            “Interiors are a huge part of what we do,” says Murray. “We always
            create a narrative about the interiors that brings together the
            campus culture, programming, and region.” In outlining the narrative
            for the Gonzaga project, Murray and his team explored a variety of
            influences, from the natural settings in and around Spokane to the
            types and disciplines of performance—chamber music, symphonies,
            ballet, opera, and so forth—that the spaces will support. “In the
            last decade,” says Murray, the “whole notion of interdisciplinary
            space has really risen. For example, the rehearsal hall at Gonzaga
            can showcase dance one minute, then deploy telescopic seats for a
            recital hall, or even set up for a 120-person orchestra in the next
            hour. And it sounds great.”
          </p>
          <p>
            Optimizing sound in these spaces, especially when they are required
            to support a variety of performance types, presents both an aural
            and visual challenge. The Pfeiffer team works with the top
            acousticians in the world to develop its projects’ acoustic
            systems—often intricate panel, drape, and screen arrangements—that
            amplify and diffuse sound. To visualize how these venues will look
            and feel, the team values an analog approach. “Our work is primarily
            three dimensional. There is nothing more useful than a physical
            model of the theater,” continues Murray. “Looking into a sectional
            model at quarter scale or larger, and sticking your head in there,
            gives everyone—architects, faculty, contractors, donors—a better
            sense of the spaces.”
          </p>
          <p>
            The Gonzaga venue, which opened in 2019 and received national
            acclaim for its design, is a harmonious addition to the Gonzaga
            campus and an inspiring space to practice, learn, observe, and
            perform. “It’s design from the outside in and inside out,” says
            Murray.
          </p>
          <div className="images-single">
            {" "}
            <LazyLoad offset={300}>
              <img
                src={Beach}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1668630488/The%20Narrative%202022/Jones_Beach_1_mr98ds.jpg"
                alt="Northwell Health at Jones Beach"
              />
            </LazyLoad>
            <h6>
              Northwell Health at Jones Beach, a 15,000-seat beachfront
              amphitheater in Wantagh, NY, is one of the first Live Nation
              venues to be upgraded as part of its brand-wide master plan.
              Rendering Copyright MEIS
            </h6>
          </div>
          <h3>Technology Meets Hospitality: Live Nation Venues</h3>
          <p>
            A longtime passion for live music led Dan Meis, founder of MEIS—A
            Perkins Eastman Studio, to reimagine the design of outdoor venue
            amenities and circulation. “I had gone to a concert with my wife in
            Los Angeles at an amphitheater, The Greek, and I started to think
            about how the VIP experience—seating, food and beverage, and
            parking—at outdoor venues could be augmented. It occurred to me that
            the amphitheater market hadn’t really done a lot with that.”
          </p>{" "}
          <p>
            In 2018, Meis reached out to concert promoter and venue manager Live
            Nation with a proposition to rethink these spaces. Serendipitously,
            the company had recently wrapped an extensive overhaul of its indoor
            venues and was beginning to turn its attention to the more than 40
            amphitheaters it operates across the United States and Canada. Meis
            and his team were invited to collaborate on a master-planning
            effort. Today, work is well underway on a venue-wide experience
            audit for Live Nation’s amphitheaters. For the benefit of the
            general admission ticket buyer, the redesign priorities are clear:
            create new wayfinding systems to improve circulation; redesign
            concessions to decrease wait time; and restructure seating to
            optimize sight lines to both the stage and large-scale video
            screens.
          </p>
          <p>
            For many VIP ticket holders, especially celebrities and other
            prominent patrons, attending concerts is as much about the social
            scene as it is about the performance. “Celebrities like to be
            seen—it’s part of their brand,” says Meis. To satisfy the VIP set,
            the MEIS team is identifying areas to insert “rock boxes” in front
            of the stage that serve as miniature clubs; by removing a small
            percentage of seats, the team proposes to create lounge areas
            complete with personal waitstaff, cocktail and food stations, and
            lounge seating. And some venues will incorporate lofts within the
            wings above the stage floor (similar to opera boxes but even closer
            to the performers), where VIP audience members will have a close-up
            view of both the performance and backstage activities.
          </p>
          <p>
            Integrated technology is playing a major role in the overhaul of
            Live Nation venues. From ticketing systems to LED screens, it
            provides a more efficient and personalized experience for the
            attendees, staff, and performers. Video boards, for example, are a
            “huge issue to address in the outdoor venues,” says Meis. “The
            different camera angles of the performance, similar to sporting
            events, are a big part of the experience.” And while the ability to
            hear and see the performance is essential, says Meis, “we usually
            don’t need to have acoustical consultants—just speakers because
            every band brings in their own sound equipment.”
          </p>
          <p>
            One of the first venues to implement MEIS’s innovations is Northwell
            Health at Jones Beach Theater, located on Long Island in Wantagh,
            NY. Upgrades to this 15,000-seat beachfront venue include more
            efficient LED lighting, wider concourses to prevent bottlenecks at
            concessions and bathrooms, and expanded dining options. With several
            summer concert seasons under its belt, the revamped Jones Beach
            amphitheater experience is second only to nature’s own immersive
            performance—the everchanging skies above and the ebb and flow of the
            bay beyond. <strong>N</strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
