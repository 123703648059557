import { React } from "react";
import NavBtns from "../components/NavBtns";
import LazyLoad from "react-lazy-load";

// import ReactDOM from "react-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import images
import BG from "../img/quick-on-the-draw/Omar-IMG_1959_2022-09-21-min.jpg";
import FrancisStevens from "../img/quick-on-the-draw/IMG_1950_2022-09-21-min.jpg";
import Tower from "../img/quick-on-the-draw/Andres_Pastoriza_color_drawing_hirxte-min.png";
import Bridge from "../img/quick-on-the-draw/_Tilikum_Mt_Hood_Sketch_t4ykwy-min.jpg";
import Raycom from "../img/quick-on-the-draw/nicks_sketches_cleaned-min.jpg";
import Dublin from "../img/quick-on-the-draw/Ballbridge_Dublin_bclaul-min.jpg";
import Earthspace from "../img/quick-on-the-draw/Chhavi-2022_09_25_Views_and_Places-min.png";
import Christy from "../img/quick-on-the-draw/Image_ld5bq0-min.jpg";
import Everton from "../img/quick-on-the-draw/Meis-Everton_adjused_xwuirr-min.jpg";
import Truck from "../img/quick-on-the-draw/food_truck-min.jpg";
import People from "../img/quick-on-the-draw/Omar_C_IMG_1965_2022-09-21_20_55_44_2_yhlhkg-min.jpg";
import Watercolors from "../img/quick-on-the-draw/Chhavi_watercolor-min.jpg";
import Dinosaurs from "../img/quick-on-the-draw/Ling-Dinosaur-min.jpg";
import Cowboy from "../img/quick-on-the-draw/Ty_Kaul_ch6zyz-min.jpg";
import Cartoons from "../img/quick-on-the-draw/Andres_P-lunch_box_dcneht-min.jpg";
import Sculpture from "../img/quick-on-the-draw/CrisM-Rodin_vcdv5y-min.jpg";
import Roger from "../img/quick-on-the-draw/Nicks_father_in_law-min.jpg";
import MeisSketches from "../img/quick-on-the-draw/Meis-Sketch_Books-min.jpg";

function NewComponent(props) {
  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  return (
    <div className="page page-container quick-on-the-draw">
      <NavBtns
        prev="/the-wharf-at-long-last"
        next="/transformational-designs"
      />
      <div className="hero-wrapper">
        <div className="hero">
          <div className="hero-images">
            <img
              id="hero-bg"
              src={BG}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1668630521/The%20Narrative%202022/Omar-IMG_1959_2022-09-21_20_55_19_d75aid.jpg"
              alt="Quick On The Draw background"
            />
          </div>
          <div className="hero-text">
            <h2>
              Quick<span>on the</span>
            </h2>
            <h1>Draw</h1>
            <h6 className="by-line">by Jennifer Sergent</h6>
          </div>
        </div>
      </div>
      <p className="caption">
        Principal Omar Calderón Santiago included this sketch, featuring the
        campus green and main library, in a 2022 competition entry for the Huzho
        University of Technology in Huzho, China.
      </p>
      <div className="section">
        <div className="static">
          <h6>
            Perkins Eastman promotes hand sketching as an invaluable design tool
            that technology cannot replace.
          </h6>
          <p className="cap">
            Decades after graduating from Pratt, Perkins Eastman Associate
            Principal Ty Kaul says his most influential instructors taught by
            drawing. “They would sit down and analyze your project with a few
            simple sketches that clarified the idea.”
          </p>
        </div>
      </div>

      <div className="section">
        <div className="static">
          <p>
            In practice, Kaul adds, the first sketches of a project— imperfect
            and incomplete though they may be—can provide that spark of
            inspiration from which a building will emerge. “When you draw, you
            are in a special place. You are in a separate nation—the
            imagination.”
          </p>
          <p>
            Now that digital technology has become the default design tool among
            architects, some question the usefulness of drawing by hand. The
            Yale School of Architecture even hosted a symposium back in 2012
            that was provocatively titled “Is Drawing Dead?” Yet Kaul and many
            of his colleagues in the New York studio and across the firm remain
            steadfast adherents.
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={FrancisStevens}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1670815187/The%20Narrative%202022/IMG_1950_2022-09-21_20_58_56_j6jeac.jpg"
                alt="concept sketch for an expanded
                  community space at the School Without Walls"
              />
            </LazyLoad>
            <h5>
              Omar Calderón Santiago drew a concept sketch for an expanded
              community space at the School Without Walls at Francis-Stevens for
              early-childhood through middle-school students in Washington, DC.
            </h5>
          </div>
          <h3>Power of the Pen</h3>
          <p>
            Omar Calderón Santiago, a principal in Washington, DC, sees “a quiet
            revolution” afoot that’s reviving the power of sketching. “People
            are responding to the immediacy and the intimacy that a hand drawing
            gives you,” he says. Calderón Santiago and members of the K-12
            Education practice team recently arrived at a client interview with
            nothing but sketches to demonstrate their work. “They were so
            appreciative and thankful that someone took the time to draw by
            hand,” he says. Associate Principal Christy Schlesinger, who also
            works in DC, explains why: “It’s a tool you use during a meeting to
            show that you’re listening—that you’re translating their words into
            something they can see.”
          </p>
          <p>
            Architects also sketch to work through their own ideas. Co-CEO and
            Executive Director Nick Leahy describes this essential process in
            the updated edition of Eric Jenkins’s Drawn to Design: Analyzing
            Architecture through Freehand Drawing, which was published this
            fall: “The bodily, physical interaction that occurs through a
            drawing instrument literally helps architects ‘feel the weight of
            things’—a feeling that cannot be carried over using a keyboard,
            mouse, or tablet.” In a conversation for this article, Leahy adds,
            “When you’re drawing, it focuses the mind.”
          </p>
          <section className="image-cluster">
            <Slider {...settings} className="images-wrapper">
              <div className="images-single">
                <img
                  src={Tower}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1668630406/The%20Narrative%202022/Andres_Pastoriza_color_drawing_hirxte.png"
                  alt="concept sketch for an expanded
                  community space at the School Without Walls"
                />

                <p>
                  Principal Andrés Pastoriza uses color to illustrate concepts
                  in his sketches, such as the passive design features of a
                  residential tower in Santo Domingo, Dominican Republic.
                </p>
              </div>
              <div className="images-single">
                <img
                  src={Bridge}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1668630391/The%20Narrative%202022/_Tilikum_Mt_Hood_Sketch_t4ykwy.jpg"
                  alt="concept sketch for an expanded
                  community space at the School Without Walls"
                />

                <p>
                  Don MacDonald, principal and founder of Donald MacDonald
                  Bridge Architects—A Perkins Eastman Studio, used a drawing to
                  demonstrate how a new pedestrian and public-transit bridge in
                  Portland, OR, would complement the profile of Mount Hood,
                  visible in the distance.{" "}
                </p>
              </div>
              <div className="images-single">
                <img
                  src={Raycom}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136644/The%20Narrative%202022/nicks_sketches_cleaned_x1fvil.jpg"
                  alt="concept sketch for an expanded
                  community space at the School Without Walls"
                />

                <p>
                  For the Raycom Infotech Park in China, Co-CEO and Executive
                  Director Nick Leahy produced a series of explanatory sketches
                  to describe the campus’ gateway buildings to the client.{" "}
                </p>
              </div>
              <div className="images-single">
                <img
                  src={Dublin}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136485/The%20Narrative%202022/Ballbridge_Dublin_bclaul.jpg"
                  alt="concept sketch for an expanded
                  community space at the School Without Walls"
                />

                <p>
                  Senior Associate Amit Arya used this sketch in a presentation
                  to the town planning commission in Dublin, Ireland, for a
                  communal work/live project. The intent was to show the open,
                  neighborly welcome the building would offer in its urban
                  context.{" "}
                </p>
              </div>
              <div className="images-single">
                <img
                  src={Earthspace}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136503/The%20Narrative%202022/Chhavi-2022_09_25_Views_and_Places_hjg7vk.png"
                  alt="concept sketch for an expanded
                  community space at the School Without Walls"
                />

                <p>
                  Associate Principal Chhavi Lal produced a sketch to show how
                  Earthspace, a mixed-use development in Surat, India, features
                  public green space on the campus’ perimeter that facilitates
                  views of the surrounding golf course.{" "}
                </p>
              </div>
              <div className="images-single">
                <img
                  src={Christy}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1668630475/The%20Narrative%202022/Image_ld5bq0.jpg"
                  alt="concept sketch for an expanded
                  community space at the School Without Walls"
                />

                <p>
                  Associate Principal Christy Schlesinger makes quick sketches
                  for clients to test out ideas and concepts. This one, for the
                  lobby design of a Washington, DC, government agency building,
                  features a rammed-earth wall.
                </p>
              </div>
            </Slider>
          </section>
          <p>
            That concept played out on a grand scale in Liverpool, England,
            where the new Everton Stadium is rising from the historic
            Bramley-Moore Dock on the River Mersey. When Dan Meis, a principal
            and founder of MEIS—A Perkins Eastman Studio in Los Angeles and New
            York, first met with the chairman of the Everton Football Club, his
            instinct was for the stadium design to evoke the rough-hewn, gritty
            history of the former coal-handling dock. Yet his client preferred a
            sleeker, more modern profile. In an attempt to bridge the two
            concepts, Meis later sat down with his sketchbook, exploring new
            configurations as they emerged from his pen. In the end, he produced
            a sketch that looks like a cresting wave is washing over the
            stadium. “The chairman has been 100-percent sold on the design ever
            since,” Meis says, noting that the plans call for a curved,
            glass-and-steel “wave” over a rugged brick base that looks as if
            it’s always been part of the dock. “If you sketch for the client,
            they feel like they’re part of the process. They see the thinking
            behind it,” he says.
          </p>
          <p>
            In contrast, a beautiful computer rendering can look like a fait
            accompli, with no room for further alteration, imagination, or
            participation. Giaa Park, an associate principal in New York,
            compares it to cake decorating—pretty on the surface, but there’s no
            way to know what’s underneath. “It often results in losing its
            abstract value, because computer graphics can overpower the idea and
            be presented—prematurely— as a final project,” she says. “A sketch
            is an intuitive thinking tool searching for a solution to the
            essence of what a building should be.”
          </p>
          <p>
            The benefits of sketch-driven client presentations also extend to
            public meetings, says Don MacDonald, founder of Donald MacDonald
            Bridge Architects— A Perkins Eastman Studio in San Francisco. “I
            always find it’s better to start out with a sketch at a public
            hearing,” he says. “When we’re done, everyone feels like they’ve had
            some say in the process.” That approach brought him success in
            Portland, OR, throughout a series of 15 hearings to develop the
            design for Tilikum Crossing, a bridge for pedestrians, cyclists, and
            public transit that crosses the Willamette River. “I did [the
            hearings] with drawings,” says MacDonald, who later included them in
            a book he wrote about the crossing, which is also known as the
            “Bridge of the People.”
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Everton}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1668630505/The%20Narrative%202022/Meis-Everton_adjused_xwuirr.jpg"
                alt="Initial Sketch of Everton Stadium"
              />
            </LazyLoad>

            <h5>
              Principal Dan Meis’s initial sketch provided the design foundation
              for what would become the Everton Stadium in Liverpool, England.
            </h5>
          </div>
          <h3>Conflict Resolution</h3>
          <p>
            From a practical perspective, notes New York-based Principal Andrés
            Pastoriza, “I’ve always valued [sketching] as a tool to communicate
            ideas more clearly.” On a construction site, he points out, a design
            detail can be clarified with a pencil sketch on an unfinished wall.
            In the studio, he often asks his colleagues to sketch their feedback
            directly onto a printout of a blueprint, computer model, or
            rendering. “It’s a challenge to communicate ideas on any of those
            digital platforms in the same way,” he says. “It just works better
            when you can do it in real life.”
          </p>{" "}
          <p>
            Chhavi Lal, an associate principal in Mumbai, shares a breakthrough
            moment she recently had with a new designer to the studio who was
            having trouble with a plan detail she was trying to develop in a
            computer program. Lal asked her to take a break from the screen and
            start drawing. “Somehow, the whole plan just came together. That is
            the power of hand work,” she says. “You don’t want to get inhibited
            by a computer graphic.”
          </p>
          <p>
            Principal Cristobal Mayendia, who is based in New York, also
            emphasizes the benefits of moving from screen to paper, recalling a
            notable example with a hospital client in Atlanta who was eager to
            see more progress on the computer-generated massing models for their
            project. Mayendia suggested a daylong sketching session to work
            things out. “It was all pen and paper,” he says. Through those
            drawings, the client team could see the building truly come to life,
            embracing the curvature of the planned landscape design and
            facilitating a seamless flow of people in and out. As Mayendia
            describes it, “you have to draw a lot to understand space, the human
            body, cityscapes, buildings, and details.”
          </p>
          <p>
            And if you draw with a sense of humor to break the ice, even better,
            says Associate Ling Zhong, who is known for his fanciful cartoon
            drawings. “I tend to make things silly,” says Zhong, who spent
            downtime during the pandemic shutdown sketching images of New York
            from Google Street View, then adding cartoon animals into the
            cityscape for fun (right). “I can turn very serious situations into
            a relaxed context with cartoons,” he says. At its core, drawing is
            “not about the blocky building. It’s about the stories that flow
            through the building.”
          </p>
          <h3>Blending Both Worlds</h3>{" "}
          <p>
            Luckily, the iPad—along with many sketching and illustration apps—is
            allowing architects to blend the art of sketching with the oomph of
            computing. “It has made my work go to the next level,” says New York
            Senior Designer and Senior Associate Amit Arya, who calls programs
            such as Morpholio Trace “Photoshop for the hand.” Using an iPad and
            Apple Pencil, he can sketch on top of computer models to adjust or
            build upon what’s already there—or create something new—and
            instantly share it with his team. “If you had 10 ideas” for a
            project, he says, it could take up to three days to model them all
            in a computer program such as Rhino, but with sketching software,
            “you can produce it all within a day.”
          </p>{" "}
          <p>
            Calderón Santiago bought his first iPad during the pandemic, and the
            device has permanently changed the way he works. With apps such as
            Morpholio Trace, Procreate, and SketchUp, he says, “I still have the
            ability to connect my brain to my hand,” and, like sketching on
            paper, “there’s an immediacy there that’s been transformative for
            me.” He’s not alone in this regard—these widely accessible apps have
            enabled a growing circle of architects, illustrators, and artists to
            celebrate and share each other’s designs—and the apps themselves
            promote the best ones on their platforms. Calderón Santiago hopes
            this thriving community will swing the pendulum back from the almost
            exclusively digital education that most architecture schools now
            offer toward a better balance between hand drawing and
            computer-generated design.
          </p>{" "}
          <p>
            “If drawing is thinking with a pencil, schools are not teaching
            students to think,” says Kaul. Plus, he continues, sketches are just
            plain memorable: “You can make a napkin sketch to explain something
            to a client,” he says, and what you end up with is a hand-drawn
            design concept that someone can put in their pocket. “It’s a much
            more productive exchange than, ‘Here’s the flash drive—it’s all
            complete.’”
          </p>{" "}
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Truck}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136571/The%20Narrative%202022/food_truck_swn6yy.jpg"
                alt="food truck sketch"
                id="food-truck"
              />
            </LazyLoad>
          </div>
        </div>
      </div>
      <div className="personal-pursuits-img__wrapper">
        <LazyLoad offset={300}>
          <img
            src={People}
            // src="https://res.cloudinary.com/nickpug/image/upload/v1671149055/The%20Narrative%202022/Omar_C_IMG_1965_2022-09-21_20_55_44_2_yhlhkg.jpg"
            alt="people standing in a line"
            id="personal-pursuits-img"
          />
        </LazyLoad>

        <h5>
          Omar Calderón Santiago likes to do subject sketches when he’s out and
          about. “Sometimes I get a kick out of drawing people I see on my
          commute,” he says. “I have also been known to draw my unsuspecting
          colleagues while on a Teams or Zoom call.”
        </h5>
      </div>
      <div className="section">
        <div className="static">
          <br />
        </div>
      </div>
      <h2 className="personal-pursuits">
        <span>Personal</span> Pursuits
      </h2>
      <div className="section">
        <div className="static">
          <div className="image-cluster">
            <Slider {...settings} className="personal-pursuits-images">
              <div className="images-single">
                <img
                  src={Watercolors}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136504/The%20Narrative%202022/Chhavi_watercolor_t41lxu.jpg"
                  alt="Chhavi_watercolor"
                />

                <h5>
                  Chhavi Lal turns to her watercolors after work, painting
                  landscapes as a way to unwind. “I’m completely focused for
                  those couple of hours—cut off from everything else. It’s just
                  so meditative.”
                </h5>
              </div>
              <div className="images-single">
                <img
                  src={Dinosaurs}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136605/The%20Narrative%202022/Ling-Dinosaur_zapnke.jpg"
                  alt="dinosaur sketch"
                />

                <h5>
                  Ling Zhong loves to draw with his nine-year-old daughter, a
                  budding artist in her own right. She’s left-handed and he’s
                  right-handed, so they can sit side by side and draw the same
                  thing simultaneously. He also draws with his five-year-old
                  son: “Right now, I’m a pro at drawing dinosaurs.”
                </h5>
              </div>
              <div className="images-single">
                <img
                  src={Cowboy}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1671458576/The%20Narrative%202022/Ty_Kaul_ch6zyz.jpg"
                  alt="cowboy sketch"
                />

                <h5>
                  Ty Kaul used drawings to help his drafting students when he
                  taught at the New York Institute of Technology, just as his
                  professors did for him. He frequently used a sketch of Le
                  Corbusier’s famous “Modulor Man” dressed as a cowboy: “Just
                  like the gunslingers of the Wild West, the one who draws the
                  most and the fastest usually ‘lives’ in design studio to
                  design another day.”
                </h5>
              </div>
              <div className="images-single">
                <img
                  src={Cartoons}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1668630425/The%20Narrative%202022/Andres_P-lunch_box_dcneht.jpg"
                  alt="lunch box sketch"
                />

                <h5>
                  Andrés Pastoriza paints watercolors on the weekends with his
                  daughter—“just some art time to let loose,” he says. “I also
                  do a series of cartoons for her school snack box that are more
                  personal, but have become a daily activity two years running.
                  It’s a nice visual diary of what was happening in our family
                  that day.”
                </h5>
              </div>
              <div className="images-single">
                <img
                  src={Sculpture}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1668630436/The%20Narrative%202022/CrisM-Rodin_vcdv5y.jpg"
                  alt="sketch of a man turned away"
                />

                <h5>
                  Cristobal Mayendia prefers drawing memorable scenes to
                  photographing them. “I feel like when we travel, we go too
                  fast. Drawing is a way to slow down.” His fondest memory of a
                  trip to Paris last summer is sketching with his daughter in
                  the garden of the Rodin Museum.
                </h5>
              </div>
              <div className="images-single">
                <img
                  src={Roger}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136643/The%20Narrative%202022/Nicks_father_in_law_pjatci.jpg"
                  alt="sketch of Nick Leahy's father in law"
                />

                <h5>
                  Nick Leahy is never without a sketch pad, and he fills it with
                  all sorts of observations, from a vase of flowers to the
                  people he sees on a Teams screen. He recently did a study of
                  his father-in-law, Roger Stemen, during a visit to Stemen’s
                  apartment.
                </h5>
              </div>
              <div className="images-single">
                <img
                  src={MeisSketches}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136628/The%20Narrative%202022/Meis-Sketch_Books_zwexpv.jpg"
                  alt="Meis sketch books"
                />

                <h5>
                  Dan Meis has kept every sketch he’s ever made, and he’s
                  talking to a publisher about compiling them into a book. He
                  also helped raise money for World Elephant Day this year by
                  promising to send a sketch of Everton’s new stadium to anyone
                  who matched his own $500 pledge. “I ended up making more than
                  30 sketches!” N
                </h5>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
