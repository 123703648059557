import React from "react";
import { Link } from "react-router-dom"; // Import the Link component

function NewComponent(props) {
  return (
    <div className="nav-btns">
      {props.prev ? (
        <Link to={props.prev} className="prev-btn">
          &lt;
        </Link>
      ) : (
        ""
      )}
      {props.next ? (
        <Link to={props.next} className="next-btn">
          &gt;
        </Link>
      ) : (
        ""
      )}
    </div>
  );
}

export default NewComponent;
