import { React } from "react";
import NavBtns from "../components/NavBtns";
import LazyLoad from "react-lazy-load";

import Emily from "../img/take-five/Pierson-Brown_Emily_06-2018_Direct_Color_HR_mr_snjwnd.jpg";
import Frances from "../img/take-five/Halsband_Frances_08-2022_Direct_Color_mr_its0qw.jpg";
import Bethany from "../img/take-five/Yoder_Bethany_02-2017_Direct_Color_HR_mr_ubyfet.jpg";
import Matthew from "../img/take-five/Bell_Matthew_02-2017_Direct_Color_HR_mr_jzir0h.jpg";
import Alberto from "../img/take-five/Cavallero_Alberto_05-2021_Direct_Color_mr_ukszn0.jpg";
import Carolyn from "../img/take-five/Day_Carolyn_12-2019_Direct_Color_mr_t2tqvj.jpg";
import StateHouse from "../img/take-five/1-StateHouseMeeting_t3rylc.jpg";
import Life from "../img/take-five/2Life_Communities_-_Opus_Newton_66571.00.0_Render_Ext_Aerial_View_2_mr_k2pcgb.jpg";
import AIA from "../img/take-five/AIA_Women_s_Leadership_Summit_2022_hke468.jpg";
import Working from "../img/take-five/Architects_Working_06-2018_PGH_Team_Photo_17_Color_HR_mr_ufraxc.jpg";
import CarolynSon from "../img/take-five/Carolyn_and_her_son_Rowan_qeawk5.jpg";
import FDR from "../img/take-five/FDR_Library_Visitor_Center_Ext_Courtyard_bjh8nz.jpg";
import Gala from "../img/take-five/Gala_for_Philip_Johnson_when_Frances_was_dean_at_Pratt_qykpn0.jpg";
import Kyiv from "../img/take-five/H_mhbypv.jpg";
import Bell from "../img/take-five/125258_f6r3k4.jpg";
import PGH from "../img/take-five/MKTG1_00_0_N7_mr_zfdrzx.jpg";
import Wuxi from "../img/take-five/Wuxi_closeup_c12_drppie.jpg";
import Workshop from "../img/take-five/Z100_UB_Lib_N3_large_1_tn2h0q.jpg";

function NewComponent(props) {
  return (
    <div className="page page-container take-five">
      <NavBtns prev="/the-third-act" next="/the-annual-excellence-portfolio" />
      <div className="section hero">
        <div className="hero-text">
          <h1>
            <span className="take">Take </span>{" "}
            <span className="five">five</span>
          </h1>
          <h6>
            PEople from across the firm answer five questions about their work,
            their views on the profession, and their commitment to inclusivity
            and mentorship.
          </h6>
          <p className="by-line">by Emily Bamford</p>
        </div>
      </div>
      <div className="section person" id="emily-pierson-brown">
        <h2>
          <span>Take Five</span> with Emily Pierson-Brown
        </h2>
        <div className="person-text">
          <LazyLoad offset={300}>
            <img
              src={Emily}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1671114055/The%20Narrative%202022/Pierson-Brown_Emily_06-2018_Direct_Color_HR_mr_snjwnd.jpg"
              alt="Emily Pierson-Brown"
            />
          </LazyLoad>
          <p>
            With a design career spanning more than 20 years, including six at
            Perkins Eastman, Emily Pierson-Brown is PEople Culture Manager of
            the firm, an active member of the Women’s Leadership Initiative and
            DE&I Operations Team, and a passionate advocate for the
            amplification of marginalized voices. She is also the recipient of;
            the 2022 AIA Pennsylvania Impact Designer Award.
          </p>
        </div>
        <div className="questions">
          <div className="qa">
            <p className="question">
              You were recently named the firm’s first PEople Culture Manager.
              Congratulations! What do you hope to accomplish in this role, and
              why are issues of diversity, equity, and inclusion close to your
              heart?
            </p>
            <div className="answer">
              <p>
                I am immensely excited to take on this role. In many ways, I see
                it as an extension of the work I am already doing with my Senior
                Living clients, the Women’s Leadership Initiative, and the
                Pittsburgh studio as we create new ways of working in a hybrid
                environment.
              </p>
              <p>
                I was raised by a single mom to believe I could do and
                accomplish anything, so I was unprepared for the sexism and
                condescension I experienced in architecture school and as a
                young designer. It’s part of why I left the profession for 10
                years. I needed to find my voice as a person, a woman, a member
                of the LGBTQIA+ community, and an advocate for myself and
                others.
              </p>
              <p>
                When I went on job interviews after grad school, I always
                mentioned my wife to test if it was a place that would be safe
                for all facets of my identity. At first mention of my wife at my
                Perkins Eastman Pittsburgh interview, Arch Pelley immediately
                began talking about his husband. The relief of both being seen
                and recognizing something of myself in a respected firm leader
                is a feeling everyone should experience.
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              What lessons have you learned as a leader in the firm, and what
              wisdom do you hope to impart to others coming up?
            </p>
            <div className="answer">
              <p>
                I am a big believer in collective leadership. None of us is
                doing this work alone, whether it is designing a project or
                advocating for gender equity. A multitude of voices is crucial.
                I am taking on the PEople Culture Manager role because there
                needs to be clarity around vision, decision-making, and
                implementation of our DE&I goals. Our DE&I initiative grew out
                of grassroots efforts in several studios and will need to have
                that local, personal component to be effective.
              </p>
              <p>
                Leadership isn’t necessarily about being in charge. Each of us
                can lead from wherever we are at the current moment—by
                encouraging collaboration, connecting with different people,
                understanding what’s not being said as much as what is, and
                inspiring people.
              </p>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={AIA}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136479/The%20Narrative%202022/AIA_Women_s_Leadership_Summit_2022_hke468.jpg"
                alt="AIA Women’s Leadership Summit"
              />
            </LazyLoad>
            <h6>Rendering Courtesy Perkins Eastman</h6>
            <h6>
              At this year’s AIA Women’s Leadership Summit, Perkins Eastman was
              well represented: (L to R): Emily Perchlik, Nasra Nimaga,
              Katherine LoBalbo, Ashley Chung, Carisima Koenig, Huiyao Chen,
              Betsey Olenick Dougherty, Brianne Pham, Sarah Oakes, Kendall
              Tynes, Deana Vogelgesang, Yinan Liu, Kathryn Wagner, Venus
              Carbuccia, and Emily Pierson-Brown, as well as Leslie Moldow and
              Yolanda Lettieri, who are not pictured.
            </h6>
          </div>
          <div className="qa">
            <p className="question">
              Your work with our Senior Living practice has been lauded. What
              first sparked your interest in designing spaces for older adults?
            </p>
            <div className="answer">
              <p>
                To be honest, I didn’t seek out Senior Living design, but I
                happened to have fallen into a perfect fit. It was an element of
                career serendipity I never could have predicted.
              </p>
              <p>
                Perkins Eastman’s studio in Washington, DC, mentored one of my
                grad school studios at Catholic University. When I joined the
                Perkins Eastman Pittsburgh studio, my background in
                single-family residential and large-scale planning turned out to
                be a good fit for Senior Living clients. There is a certain
                mandate I feel designing spaces that are often the last rooms a
                person will inhabit. Making those spaces meaningful is not just
                a job; it’s a vocation.
              </p>
              <p>
                Our visioning process is inclusive. We often include current
                residents and staff in these conversations and end up with
                better outcomes as a result.
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              You are a co-founder of Women+ in Design PGH, board member of AIA
              Pittsburgh, and adjunct studio instructor at the University of
              Pittsburgh. Why are these roles important to you?
            </p>
            <div className="answer">
              <p>
                Each of these engagements is a two-way street; I gain as much as
                I give. When we first invited a few friends for happy hour to
                see what other firms in the region were doing in terms of gender
                equity, we had no idea that Women+ in Design PGH would grow into
                an independent group with over 600 people on its mailing list in
                fewer than four years.
              </p>
              <p>
                That work led me to the board of AIA Pittsburgh, which I joined
                this year. I wanted to engage the guiding professional
                organization to be able to continue advocating for women, BIPOC,
                and LGBTQIA+ architects and designers, as well as those in other
                underrepresented groups.
              </p>
              <p>
                Teaching at Pitt helps me do this work in a different way. I was
                one of the first female studio instructors in the program and
                have had numerous female students tell me how my presence makes
                a difference to them. The Pitt students bring an incredibly
                thoughtful lens of social justice to their projects. I love
                helping them shape their visions of a better world, and I can’t
                wait for them to get out here and change it.
              </p>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Life}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136454/The%20Narrative%202022/2Life_Communities_-_Opus_Newton_66571.00.0_Render_Ext_Aerial_View_2_mr_k2pcgb.jpg"
                alt="2Life Communities: Opus Newton"
              />
            </LazyLoad>
            <h6>Courtesy Nasra Nimaga</h6>
            <h6>
              Emily Pierson-Brown led the design team for 2Life Communities:
              Opus Newton, an expansive senior living community in Newton, MA.
            </h6>
          </div>
          <div className="qa">
            <p className="question">
              What changes would you like to see in the architecture profession
              in the coming years?
            </p>
            <div className="answer">
              <p>
                I am looking forward to the days when our design teams reflect
                the communities we are working in and when marginalized voices
                are regularly brought into the conversations about vision and
                goal setting. We [the design professions at large] are doing
                some of this now, but it needs to become standard practice.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section person" id="frances-halsband">
        <h2>
          <span>Take Five</span> with Frances Halsband
        </h2>
        <div className="person-text">
          <LazyLoad offset={300}>
            <img
              src={Frances}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1671114055/The%20Narrative%202022/Halsband_Frances_08-2022_Direct_Color_mr_its0qw.jpg"
              alt="Frances Halsband"
            />
          </LazyLoad>
          <h6>
            Copyright Valery Petrova/CEO Portrait Photography | Courtesy Perkins
            Eastman
          </h6>
          <p>
            A principal in the firm’s New York studio, Frances Halsband is a
            founding partner of Kliment Halsband Architects, now Kliment
            Halsband Architects—A Perkins Eastman Studio, and an award-winning
            designer of university projects and campus plans with five decades
            of experience. She was recently named the 61st Chancellor of the
            College of Fellows of the American Institute of Architects.
          </p>
        </div>
        <div className="questions">
          <div className="qa">
            <p className="question">
              Kliment Halsband Architects (KHA), which you founded with your
              husband Robert Kliment in New York in 1972, is celebrating two
              milestones this year: its 50th anniversary and joining forces with
              Perkins Eastman. What was the impetus to starting your own firm,
              and did you think it would have such longevity?
            </p>
            <div className="answer">
              <p>
                Robert and I were working at Mitchell Giurgola Architects in the
                early 1970s, and we woke up one morning and said, “We can do
                this, and we can do it better.” It was an exciting and dangerous
                prospect. We started during a recession, and we had very little
                work, but no one had any work. So it seemed normal. Our friends
                helped us by sending small jobs our way, and one of our first
                projects won a Progressive Architecture Award, which was very
                prestigious in those days. Getting the work and doing the work
                and seeing it built, seeing people using and enjoying what we
                have done, is, of course, the driver for everything that has
                happened since then—a path worth taking!
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              You were the first woman president of the Architectural League of
              New York (1985 to 1989), the first woman dean at Pratt Institute
              (1991 to 1994), and the first woman president of AIA New York
              (1991). In a notoriously male-dominated field such as
              architecture, how did it feel to hold these positions of power,
              and what impact do you hope your leadership had?
            </p>
            <div className="answer">
              <p>
                Being a woman in a man’s world was definitely a challenge. I
                realized I was an outsider, and I recognized that as an
                advantage. I had a different viewpoint. I saw things insiders
                did not see. I was thinking “outside the box” because I was
                outside the box. I’d like to think I made it possible for others
                to join me in creative rethinking. Together, we were able to
                make significant changes no single person could make alone. If I
                had “power,” which is not a word I would have used at the time,
                I used it to support new ideas about how things could be done.
              </p>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Gala}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136572/The%20Narrative%202022/Gala_for_Philip_Johnson_when_Frances_was_dean_at_Pratt_qykpn0.jpg"
                alt="Mary Buckley Dinner in honor of Philip Johnson"
              />
            </LazyLoad>
            <h6>Courtesy Frances Halsband</h6>
            <h6>
              In 1992, while dean at Pratt Institute, Frances Halsband organized
              the Mary Buckley Dinner in honor of Philip Johnson; it featured
              “Philip Johnson glasses” for attendees and an AT&T Building-shaped
              throne for the guest of honor. Front row (L to R): Halsband,
              Johnson, and Pratt President Thomas Schutte; standing row (L to
              R): architect Robert A.M. Stern, Pratt Professor Mary Buckley,
              Cathedral of St. John the Divine Dean James Parks Morton, and
              designer Massimo Vignelli; also pictured architects A. Eugene Kohn
              and Robert Segal.
            </h6>
          </div>
          <div className="qa">
            <p className="question">
              How would you say the industry has changed since you started your
              career, and what changes would you still like to see?
            </p>
            <div className="answer">
              <p>
                Everything has gotten bigger, more specialized, more profoundly
                influenced by technology, but the power of a good idea—the drive
                to make the world a better place—is still the reason we do what
                we do.
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              What is the proudest moment of your career so far?
            </p>
            <div className="answer">
              <p>
                There are a few! Winning the Firm Award from AIA National in
                1997 was a wonderful recognition. We were the smallest firm to
                have won it at that time, and I think I was the first woman
                architect to see my name carved in stone at the AIA headquarters
                in Washington, DC. Fast forward to 2018, I led a successful
                movement to demand the AIA amend the Code of Ethics to prevent
                harassment and discrimination and support equity. I had not yet
                met Brad Perkins or Mary-Jean Eastman, but, looking back, I see
                they were among the 600 Fellows of the AIA who signed my
                petition!
              </p>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={FDR}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136571/The%20Narrative%202022/FDR_Library_Visitor_Center_Ext_Courtyard_bjh8nz.jpg"
                alt="The Franklin D. Roosevelt Presidential Library Visitor & Education
              Center"
              />
            </LazyLoad>
            <h6>
              Copyright Cervin Robinson/Courtesy Kliment Halsband Architects
            </h6>
            <h6>
              The Franklin D. Roosevelt Presidential Library Visitor & Education
              Center, in Hyde Park, NY, was designed by Halsband’s firm in 2004.
            </h6>
          </div>
          <div className="qa">
            <p className="question">
              What advice would you give to aspiring architects who are looking
              to break into the industry?
            </p>
            <div className="answer">
              <p>
                I think I would go back to something I said earlier: When I
                first started, I was an outsider with a very different
                perspective from the insiders who had already joined the
                profession. Seeing things in a new and different way is an
                advantage. The world is waiting for new ideas, and those ideas
                are going to come from outsiders.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section person" id="matthew-bell">
        <h2>
          <span>Take Five</span> with Matthew Bell
        </h2>
        <div className="person-text">
          <LazyLoad offset={300}>
            <img
              src={Matthew}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1671114055/The%20Narrative%202022/Bell_Matthew_02-2017_Direct_Color_HR_mr_jzir0h.jpg"
              alt="Matthew Bell"
            />
          </LazyLoad>

          <p>
            With more than 30 years of experience in urban buildings,
            large-scale master plans, and sustainable urban and campus design,
            Matthew Bell is a principal at Perkins Eastman DC, an advocate for
            cities and urbanism, and a professor of architecture and urban
            design at the University of Maryland.
          </p>
        </div>
        <div className="questions">
          <div className="qa">
            <p className="question">
              You recently gave a lecture titled “Cities Under Siege: Kyiv &
              Odesa,” hosted by The Catholic University of America’s School of
              Architecture and Planning, about rebuilding after catastrophic
              events in Ukraine and elsewhere. What was the key takeaway from
              your talk?
            </p>
            <div className="answer">
              <p>
                My interest, in addition to concern about the tragedy of the
                current situation in Ukraine, is in how architectural and urban
                theory impacts or guides the manner in which cities are
                reconstructed or rebuilt after major catastrophes like war,
                terrorism, or natural disasters—and, in that particular case,
                how Kyiv was rebuilt after the destruction of World War II. What
                was kept (or not); what was changed, and why; how design theory
                played a role; and how good the resulting interventions turned
                out to be. The lessons for today are obvious—with the war in
                Ukraine, as well as for rebuilding efforts in other parts of the
                world such as Syria or Iraq.
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              Earlier this year, you were named a Fellow of the Congress for New
              Urbanism (CNU), an honor that recognizes your passionate advocacy
              of urban places around the world. How does your affiliation with
              the organization influence your work?
            </p>
            <div className="answer">
              <p>
                Being nominated to fellowship in the CNU is a great honor.
                Perhaps the most coherent set of values for design and
                development of our cities and towns has been articulated in the
                CNU Charter. I return from each of the annual congresses having
                learned a great deal and inspired to do even more to build great
                places and great cities.
              </p>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Kyiv}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136577/The%20Narrative%202022/H_mhbypv.jpg"
                alt="Bell traveled to Kyiv, Ukraine, for a research trip in 2015."
              />
            </LazyLoad>
            <h6>Courtesy of Matthew Bell</h6>
            <h6>
              Bell traveled to Kyiv, Ukraine, for a research trip in 2015.
            </h6>
          </div>
          <div className="qa">
            <p className="question">
              Collection 14, a new mixed-use development in Washington, DC, and
              a collaboration between Perkins Eastman DC and Perkins Eastman,
              won a Charter Award from the CNU earlier this year. What makes the
              project so special?
            </p>
            <div className="answer">
              <p>
                We believe this project makes a unique contribution to an
                emerging neighborhood in the city—the U Street Historic District
                at the intersection of 14th and U streets. Collection 14 is a
                microcosm of “Human by Design.” It brings new residential
                opportunities to the city, including some affordable housing;
                repurposes and integrates historic fabric; and offers retail,
                office, and indoor and outdoor meeting spaces that will become,
                we hope, an important addition to the network of public places
                in the neighborhood.
              </p>
              <p>
                Another special aspect of the project is the manner in which our
                team performed. The project’s complexity required an advanced
                technical approach and high levels of creativity and problem
                solving typically performed by people with many more years of
                experience than our team had at the time. It was a learning
                process for both client and design team, and the result is, I
                think, a significant contribution to the fabric of our nation’s
                capital.
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              You have been teaching graduate classes in architecture and urban
              design at the University of Maryland for three decades. What do
              you enjoy about teaching?
            </p>
            <div className="answer">
              <p>
                Teaching is a privilege, and I am grateful to have had the
                opportunity to do so for such a long time. Our students at
                Maryland are terrifically talented and quite willing to engage
                in learning about urban design and urban architecture. When they
                catch on to what you are trying to teach, it is very energizing
                and exciting. I have also been able to recruit many of our best
                graduates to Perkin Eastman, and seeing them mature into
                positions of leadership and responsibility in the firm and in
                the profession is very personally gratifying.
              </p>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Bell}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136479/The%20Narrative%202022/125258_f6r3k4.jpg"
                alt="Matthew Bell leading a graduate design studio review at the University of Maryland."
              />
            </LazyLoad>
            <h6>Courtesy of Matthew Bell</h6>
            <h6>
              Matthew Bell leading a graduate design studio review at the
              University of Maryland.
            </h6>
          </div>
          <div className="qa">
            <p className="question">
              Alongside your design career, you serve as vice president of the
              Restoring Ancient Stabiae Foundation (RAS). How did you become
              involved in this international restoration program in Italy?
            </p>
            <div className="answer">
              <p>
                My involvement is thanks to Leonardo Varone, one of our senior
                associates in the Perkins Eastman DC studio. As a graduate
                student in the architecture program at the University of
                Maryland, Leo focused on a terrific but relatively unknown set
                of ancient seaside villas in his hometown of Castellammare di
                Stabia, which is near Pompei and was destroyed in the same
                eruption of Mount Vesuvius in 79 CE. Leo’s design—connecting
                those ancient villas located on a large plateau overlooking the
                Bay of Naples to the modern city below—formed the basis of RAS.
                I became vice president in 2003, and we have sustained a robust
                program of excavations, research, and urban-design projects for
                many years.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section person" id="Bethany-Yoder">
        <h2>
          <span>Take Five</span> with Bethany Yoder
        </h2>
        <div className="person-text">
          <LazyLoad offset={300}>
            <img
              src={Bethany}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1671114055/The%20Narrative%202022/Yoder_Bethany_02-2017_Direct_Color_HR_mr_ubyfet.jpg"
              alt="Bethany Yoder"
            />
          </LazyLoad>

          <p>
            Named one of <i>Engineering News-Record (ENR) MidAtlantic’s</i> Top
            Young Professionals for 2023, Bethany Yoder is an architect and
            mentor in Perkins Eastman’s Pittsburgh studio with 10 years of
            design experience in the areas of healthcare, workplace, and sports
            and wellness.
          </p>
        </div>
        <div className="questions">
          <div className="qa">
            <p className="question">
              You were recently recognized as one of only five 2022
              Architecture, Construction, and Engineering (ACE)—ENR Outstanding
              Mentors in the nation. Congratulations! What does receiving this
              honor mean to you?
            </p>
            <div className="answer">
              <p>
                It was a great honor to be recognized for my 14-plus years of
                work with the ACE’s Western Pennsylvania affiliate. More
                importantly, the recognition is a great platform to encourage
                others to volunteer and get involved—whether through the ACE
                Mentor Program or another opportunity that impacts our industry
                and the advancement of others.
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              As a lead mentor, you have created an interior design session that
              has the highest student engagement in the ACE Pittsburgh program.
              What makes your session so appealing (30 to 60 students attend
              each year), and why does mentorship hold a special place in your
              heart?
            </p>
            <div className="answer">
              <p>
                First, I want to give a shout-out to Jane Hallinan, an interior
                designer in our Pittsburgh studio, who is also involved.
                Together, we curate the wildly successful “Interiors Session”
                our studio hosts each year. The development of the session was
                influenced over years of practice trying to find the right
                balance between providing information and giving students the
                ability to explore their own creativity through hands-on
                activities. During the session, students become engaged in
                producing mood boards and finish boards, and they leave the
                session with deliverables to use during their final
                presentation. Year after year, it is voted the best session by
                the students and other participating mentors.
              </p>
              <p>
                When I was in high school, I participated in a similar program
                as a mentee, so I know firsthand how programs like ACE influence
                the next generation and contribute to the development of the
                industry pipeline. The students’ enthusiasm, creativity, and
                innovation remind me every week why I became an architect.
              </p>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Working}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136483/The%20Narrative%202022/Architects_Working_06-2018_PGH_Team_Photo_17_Color_HR_mr_ufraxc.jpg"
                alt="Yoder and Jane Hallinan collaborated on the Interiors Session for ACE Pittsburgh."
              />
            </LazyLoad>
            <h6>Copyright Perkins Eastman</h6>
            <h6>
              Yoder and Jane Hallinan collaborated on the Interiors Session for
              ACE Pittsburgh.
            </h6>
          </div>
          <div className="qa">
            <p className="question">
              The Pittsburgh studio recently went through a major redesign. What
              do you like the most about working there, and which features of
              the new design resonate with you the most?
            </p>
            <div className="answer">
              <p>
                The free-address model! Besides being able to choose a spot that
                best fits my needs for the day, the office has provided great
                opportunities for new interactions and developing working
                relationships with colleagues that I do not typically work with
                on projects. I get to overhear what is happening in different
                practices, on different projects, and have great conversations.
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              How did your interest in architecture and design first begin, and
              how does working at Perkins Eastman enhance or nourish that
              initial passion?
            </p>
            <div className="answer">
              <p>
                I was the kid who stole her brother’s Legos to build houses for
                the Lego people. I routinely remodeled and replanned based on
                what the Lego people needed from their space (insert eye roll at
                my younger self). For me, architecture was always about creating
                spaces that influenced people’s experiences, memories, and
                feelings. How is my client going to use the space? What aspect
                of the built environment is going to affect their day? At
                Perkins Eastman, we do this daily in our work.
              </p>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={PGH}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136630/The%20Narrative%202022/MKTG1_00_0_N7_mr_zfdrzx.jpg"
                alt="Perkins Eastman’s Pittsburgh studio."
              />
            </LazyLoad>
            <h6>Photograph by Andrew Rugge/Copyright Perkins Eastman</h6>
            <h6>
              Bethany Yoder worked on the design of Perkins Eastman’s Pittsburgh
              studio.
            </h6>
          </div>
          <div className="qa">
            <p className="question">
              If you had to name a project that’s been exceptionally meaningful
              to you, which one would it be?
            </p>
            <div className="answer">
              <p>
                Every project is great because there is always something new to
                learn. If I had to pick a [single] project, it would be our own
                Pittsburgh studio. The challenge of designing a space for
                colleagues is unique because of firsthand insight into the
                studio’s needs and how we work. The pandemic added an
                interesting twist that allowed the team to explore alternate
                ways of working and adapting the built environment in response
                to the changing workplace. It’s been interesting to see how
                everyone uses the space and experiences the design.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section person" id="alberto-cavallero">
        <h2>
          <span>Take Five</span> with Alberto Cavallero
        </h2>
        <div className="person-text">
          <LazyLoad offset={300}>
            <img
              src={Alberto}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1671114055/The%20Narrative%202022/Cavallero_Alberto_05-2021_Direct_Color_mr_ukszn0.jpg"
              alt="Alberto Cavallero"
            />
          </LazyLoad>

          <p>
            Alberto Cavallero is a design principal at Pfeiffer—A Perkins
            Eastman Studio, based in the firm’s New York studio, with 30-plus
            years of experience in cultural, institutional, and performing arts
            projects.
          </p>
        </div>
        <div className="questions">
          <div className="qa">
            <p className="question">
              What makes Pfeiffer’s work so special, and how did merging with
              Perkins Eastman in 2021 strengthen its <i>je ne sais quoi</i>?
            </p>
            <div className="answer">
              <p>
                Our expertise is in performing arts centers, fine arts
                buildings, and student life buildings, and those have all led us
                into another core expertise, which is working with existing
                buildings. Take libraries, for example: For the last two
                decades, they have been in transition, in terms of what they
                offer and their purpose within an institution. No longer
                primarily repositories for books, they’re envisioned as teaching
                and student environments. It’s a rare opportunity, at the center
                of campus, to create new life for old buildings. At the
                University of Illinois Springfield’s Library Learning & Student
                Success Center, a new building at the center of that campus, the
                design of the central atrium came organically as an idea from
                the users—a friendly, student-centric space that puts vast
                resources within reach.
              </p>
              <p>
                In terms of the benefits of the merger, a single project says it
                all: Wuxi Taihu-Bay International Culture and Arts Exchange in
                China. Independently, neither Perkins Eastman nor Pfeiffer would
                have gotten far on this one, but the combined strengths of our
                top-tier performance space portfolio, the superb Shanghai
                studio, and our combined expertise in planning and retail, were
                the perfect blend. Just getting to the final competition phase
                was an achievement, and the fact that we won in competition
                against some heavy hitters is a huge point of pride. With the
                merger, we are able to do things we couldn’t have done before.
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              What drew you to designing creative spaces?
            </p>
            <div className="answer">
              <p>
                Early in my career, while at KlingStubbins, I worked on projects
                for Fortune 500 companies, science projects, master plans,
                practically every building type. But I realized I’m most excited
                by projects that touch public life. Whether it’s the MoMA
                renovation and expansion in New York, which I led while at
                Diller Scofidio + Renfro, or academic buildings, I enjoy working
                on spaces that have a positive cultural impact.
              </p>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Wuxi}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1671119766/The%20Narrative%202022/Wuxi_closeup_c12_drppie.jpg"
                alt="Rendering of the Wuxi Taihu-Bay International Culture and Arts Exchange."
              />
            </LazyLoad>
            <h6>Rendering Courtesy Perkins Eastman and Pfeiffer</h6>
            <h6>
              Rendering of the Wuxi Taihu-Bay International Culture and Arts
              Exchange.
            </h6>
          </div>
          <div className="qa">
            <p className="question">
              How would you describe your design process?
            </p>
            <div className="answer">
              <p>
                I think we’re at our best when we’re working like a think tank.
                Rather than insisting on a single idea, it’s always better to
                bring lots of well-researched ideas to the client early on, so
                that we get their feedback, build rapport, and develop
                meaningful evaluative criteria for decision-making. There’s a
                humility that comes with iteration that’s extremely important:
                To be good designers, we need to have conviction in our ideas,
                but we also have to be attuned listeners. And, by the way, this
                open and iterative process is more demanding of (and therefore
                fun for) our internal design team, which is important too.
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              Would you describe a project you’re particularly excited about?
            </p>
            <div className="answer">
              <p>
                I’m fortunate to be working with great design teams on several
                projects. I can’t pick just one, so here are two:
              </p>
              <ul>
                <li>
                  The Robotics Innovation Center at Carnegie Mellon is a new
                  building for a forward-thinking client in a field where needs
                  are constantly evolving. One of the great joys of my job is
                  learning what scientists, researchers, and teachers do and
                  creating great work environments for them.
                </li>
                <li>
                  The Wuxi project involves work with the Shanghai, New York,
                  and Los Angeles studios. At any hour, day or night, someone is
                  working on Wuxi. Sometimes, after a competition, it seems we
                  have to make so many compromises, but Wuxi is getting even
                  better as we flesh out the schematic design phase.
                </li>
              </ul>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Workshop}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1668630554/The%20Narrative%202022/Z100_UB_Lib_N3_large_1_tn2h0q.jpg"
                alt="Alberto Cavallero leads a planning workshop for students at the University of Buffalo."
              />
            </LazyLoad>
            <h6>Copyright Douglas Levere</h6>
            <h6>
              Alberto Cavallero leads a planning workshop for students at the
              University of Buffalo.
            </h6>
          </div>
          <div className="qa">
            <p className="question">
              Outside of work, you are on the board of SONUS, a nonprofit
              collective of international musicians from underrepresented
              communities. What role does music play in your life and in
              architecture?
            </p>
            <div className="answer">
              <p>
                Music is my culture—in some ways, more than my Hispanic
                heritage. My late dad was a jazz pianist, and my mom is a
                classical pianist, so I grew up immersed in music (and I play
                guitar and piano too). SONUS is a labor of love that sprung out
                of the pandemic, as it was hard for musicians to find
                performance opportunities. We started with an online
                international music festival, and we’re now extending the
                mission to in-person concerts too.
              </p>
              <p>
                On the connection between architecture and music, I love the
                physicality of sound. Orchestral music, for example, was
                originally developed in palace ballrooms, rooms that were the
                largest around but were limited by construction constraints. The
                architecture of performance spaces has evolved in response to
                the evolution of music composition, and vice versa. We want
                design to be aspirational and inspirational, and through music
                you have a clear way as to how architecture can transcend visual
                effect while serving a very real experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section person" id="carolyn-day">
        <h2>
          <span>Take Five</span> with Carolyn Day
        </h2>
        <div className="person-text">
          <LazyLoad offset={300}>
            <img
              src={Carolyn}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1671114055/The%20Narrative%202022/Day_Carolyn_12-2019_Direct_Color_mr_t2tqvj.jpg"
              alt="Carolyn Day"
            />
          </LazyLoad>

          <p>
            Carolyn Day, a senior associate in Perkins Eastman’s Boston studio,
            has more than 20 years of experience in designing technology-related
            educational facilities with a special focus on sustainable design.
          </p>
        </div>
        <div className="questions">
          <div className="qa">
            <p className="question">
              You won the 2022 AIA Young Architects Award, an honor that
              recognizes your fervent advocacy of sustainable design. What does
              this award mean to you, and does it inspire you to push the
              industry toward more sustainable practices?
            </p>
            <div className="answer">
              <p>
                In some ways, this award validates the work I’ve been doing, as
                it shows how important it is that we as an industry understand
                what sustainability and advocacy and mentorship mean. It
                reaffirms both the AIA and my peers’ commitment to
                sustainability. It pushes me to further encourage my colleagues
                to do the same. Paying forward the great mentoring that I
                received and helping others in their journey to learn and grow
                is huge, and this award motivates me to continue to do so.
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              As a key member of our Education and Science + Technology
              practices, you’ve worked on everything from elementary schools to
              research facilities, always prioritizing sustainability. Why is
              environmentally focused design important to you?
            </p>
            <div className="answer">
              <p>
                I got into architecture because I realized the value of good
                design and how much it can improve the lives of the people who
                use our buildings and inhabit them—and wellness and
                sustainability are a big part of that. Another reason
                sustainable design is important to me is that our world is
                literally burning, so it’s an issue that needs to be at the
                forefront. Having sustainability as a specialty means I’m able
                to push environmentally conscious design forward, especially
                when it comes to high-energy-use buildings. It’s important that
                we look for every way to improve because every incremental
                change counts when it comes to energy and carbon.
              </p>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={CarolynSon}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1668630399/The%20Narrative%202022/Carolyn_and_her_son_Rowan_qeawk5.jpg"
                alt="Day and her son Rowan."
              />
            </LazyLoad>
            <h6>Copyright Ana Pavesi Studio</h6>
            <h6>Day and her son Rowan.</h6>
          </div>
          <div className="qa">
            <p className="question">
              You served as co-chair of the AIA Massachusetts Government Affairs
              Committee from 2019 to 2021 and currently represent the Boston
              Society of Architects on the AIA Massachusetts board. Why did you
              get involved, and what has been your greatest accomplishment?
            </p>
            <div className="answer">
              <p>
                After realizing that just holding a picket sign wasn’t going to
                effect change, I became involved in the local AIA chapter, which
                has an active advocacy community. We’ve been able to directly
                influence legislation and regulation to make changes in
                sustainability, in wellness, in equity, and in how that all
                impacts the built environment.
              </p>
              <p>
                My greatest accomplishment during this time was helping to
                negotiate between enthusiastic environmental advocates in the
                architecture community and other architects in the state who
                didn’t want to touch the issue. We were able to craft
                legislative language, stating specific requirements for what a
                new net-zero energy code would look like, that was signed into
                law. The original bill called for a definition of net zero to be
                added into the energy code, but we knew that wasn’t enough. The
                language we wrote not only defined net zero, but also directed
                the state to create an opt-in net-zero energy code, which was
                huge. We provided guidelines on what should be included and who
                should be involved. It was absolutely a team effort, but because
                of my involvement in both USGBC Massachusetts and the board of
                AIA Massachusetts, I knew what compromises would work and what
                kind of language would get passed. Signing a letter did not make
                a difference. Sitting down with legislators did.
              </p>
            </div>
          </div>
          <div className="qa">
            <p className="question">
              You have more than 20 years of professional design experience.
              What first spurred your interest in the world of architecture?
            </p>
            <div className="answer">
              <p>
                I originally studied engineering, but, while finishing my
                undergraduate degree, I realized I wanted to design instead. I
                was living in Worcester, MA, and I saw how much architecture
                influenced the city’s neighborhoods—places that had concrete
                bunkers versus nice triple-deckers, and who had gardens and who
                didn’t, who had kids playing in the street and who didn’t. That
                made me realize architecture can have an influence on people,
                and that’s what I wanted to do.
              </p>
              <p>
                At the time, I talked to my mom about it, and she pulled out
                boxes of floor plans I had drawn in crayon when I was little.
                When I was a kid, whenever I made someone a card, I used to draw
                a little house and sign my name; that was my little trademark. I
                think the interest was there all along.
              </p>
            </div>
          </div>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={StateHouse}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136451/The%20Narrative%202022/1-StateHouseMeeting_t3rylc.jpg"
                alt="Carolyn Day at a Massachusetts State House meeting."
              />
            </LazyLoad>
            <h6>Courtesy of AIA Massachusetts</h6>
            <h6>
              Carolyn Day (fourth from right) at a Massachusetts State House
              meeting.
            </h6>
          </div>
          <div className="qa">
            <p className="question">
              On a more personal note, you recently became a mother for the
              first time. How does it feel to be a new mom?
            </p>
            <div className="answer">
              <p>
                It’s world-changing. Rowan brings me so much joy, and he has
                changed my perspective on what is important in life. He makes me
                want to work even harder to make sure there’s a great world for
                him to grow up in. <strong>N</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
