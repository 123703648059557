import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import the Link component
import NavBtns from "./components/NavBtns";
// import MetaTags from "./components/MetaTags";

import TOC from "./img/PE_The_Narrative_Fall2021_Interactive.pdf_poluar.png";

function NewComponent(props) {
  // useEffect(() => {
  //   const domain = window.location.host;
  //   const $meta = document.createElement("meta");
  //   document.head.appendChild($meta);
  //   $meta.setAttribute("property", "og:image");
  //   $meta.setAttribute("content", domain + "/img/narrative-for-social-web.jpg");
  // }, []);
  const [isActive, setActive] = useState("false");

  const toggleNav = (e) => {
    setActive(!isActive);
  };

  return (
    <div className="page page-container tableOfContents">
      {/* <MetaTags /> */}
      <NavBtns prev="/" next="/the-age-of-collaboration" />
      <div className="tableOfContents_wrapper">
        <img
          src={TOC}
          // src="https://res.cloudinary.com/nickpug/image/upload/v1663701266/PE_The_Narrative_Fall2021_Interactive.pdf_poluar.png"
          alt="table of contents"
          id="toc-title"
        />

        <div className="toc text-white">
          <div className="left">
            <h3>FEATURES</h3>
            <Link
              to="/the-age-of-collaboration"
              onClick={toggleNav}
              className="toc-item"
            >
              <p className="toc-item__text">The Age of Collaboration</p>
              <p className="toc-item__subtext">
                Perkins Eastman’s strategic alliances spurring synergies
              </p>
            </Link>

            <Link
              to="/the-wharf-at-long-last"
              onClick={toggleNav}
              className="toc-item"
            >
              <p className="toc-item__text">The Wharf—At Long Last</p>
              <p className="toc-item__subtext">
                Stories and memories from a 16-year odyssey
              </p>
            </Link>
            <h3>Stories</h3>
            <Link
              to="/quick-on-the-draw"
              onClick={toggleNav}
              className="toc-item"
            >
              <p className="toc-item__text">Quick on the Draw</p>
              <p className="toc-item__subtext">
                The virtues of sketching by hand
              </p>
            </Link>

            <Link
              to="/transformational-designs"
              onClick={toggleNav}
              className="toc-item"
            >
              <p className="toc-item__text">Transformational Designs</p>
              <p className="toc-item__subtext">
                Reimagining existing building stock to confront climate change
              </p>
            </Link>

            <Link to="/its-showtime" onClick={toggleNav} className="toc-item">
              <p className="toc-item__text">It's Showtime</p>
              <p className="toc-item__subtext">
                Agile and immersive venues for the performing arts
              </p>
            </Link>
            <Link to="/park&ride" onClick={toggleNav} className="toc-item">
              <p className="toc-item__text">Park & Ride</p>
              <p className="toc-item__subtext">
                Urban designers making it easy to leave cars behind
              </p>
            </Link>
            <Link to="/the-third-act" onClick={toggleNav} className="toc-item">
              <p className="toc-item__text">
                Living Our Best Life: The Third Act
              </p>
              <p className="toc-item__subtext">
                Senior living communities reflect cultural identities
              </p>
            </Link>

            <div className="flex-break"></div>
          </div>
          <div className="right">
            <h3>Interviews</h3>
            <Link to="/take-five" onClick={toggleNav} className="toc-item">
              <p className="toc-item__text">Take Five</p>
              <p className="toc-item__subtext">
                Q+A on the life of an architect with PEople from across the
                firm’s studios
              </p>
            </Link>
            <h3>In Review</h3>
            <Link
              to="/the-annual-excellence-portfolio"
              onClick={toggleNav}
              className="toc-item"
            >
              <p className="toc-item__text">The Annual Excellence Portfolio</p>
              <p className="toc-item__subtext">
                Perkins Eastman’s new program and publication celebrating design
              </p>
            </Link>
            <h3>PANORAMA</h3>
            <Link
              to="/a-perkins-eastman-city"
              onClick={toggleNav}
              className="toc-item"
            >
              <p className="toc-item__text">Imagine a Perkins Eastman City</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
