import React, { Component } from "react";
// import "./App.css";
import { Switch, Route, withRouter } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import MetaTags from "./components/MetaTags";
import { HelmetProvider } from "react-helmet-async";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import Nav from "./Nav.js";

import Home from "./Home.js";
import TOC from "./toc.js";
import collab from "./pages/the-age-of-collaboration.js";
import wharf from "./pages/the-wharf-at-long-last.js";
import quick from "./pages/quick-on-the-draw.js";
import showtime from "./pages/its-showtime.js";
import park from "./pages/park&ride.js";
import designs from "./pages/transformational-designs.js";
import thirdAct from "./pages/the-third-act.js";
import five from "./pages/take-five.js";
import aep from "./pages/the-annual-excellence-portfolio.js";
import city from "./pages/a-perkins-eastman-city.js";

import "./App.scss";

import ScrollToTop from "./components/ScrollToTop";

const pageArr = [
  "/",
  "/toc",
  "/the-age-of-collaboration",
  "/the-wharf-at-long-last",
  "/quick-on-the-draw",
  "/transformational-designs",
  "/its-showtime",
  "/park&ride",
  "/the-third-act",
  "/take-five",
  "/the-annual-excellence-portfolio",
  "/a-perkins-eastman-city",
];
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevDepth: this.getPathDepth(this.props.location),
    };
  }

  componentWillReceiveProps() {
    this.setState({ prevDepth: this.getPathDepth(this.props.location) });
    console.log(this.props.location);
    console.log(this.state);
  }

  getPathDepth(location) {
    // let pathArr = location.pathname.split("/");
    // console.log(location);
    // pathArr = pathArr.filter((n) => n !== "");
    // return pathArr.length;
    let pathName = location.pathname;
    console.log(pathName);
    for (let i = 0; i < pageArr.length; i++) {
      if (pathName === pageArr[i]) {
        // this.setState({});
        return i;
      }
    }
    return 0;
  }

  render() {
    const { location } = this.props;

    const currentKey = location.pathname.split("/")[1] || "/";
    const timeout = { enter: 800, exit: 400 };
    // this.getPathDepth(location);
    // console.log(this.props);
    // console.log(this.state);
    // let imageUrl;
    // console.log(location.pathname);
    // switch (location.pathname) {
    //   case "/":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/narrative-for-social-web.jpg";
    //     break;
    //   case "/toc":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/narrative-for-social-web.jpg";
    //     break;
    //   case "/the-age-of-collaboration":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/age-of-collaboration-web.jpg";
    //     break;

    //   case "/the-wharf-at-long-last":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/the-wharf-web.jpg";
    //     break;
    //   case "/quick-on-the-draw":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/quick-on-the-draw-web.jpg";
    //     break;
    //   case "/transformational-designs":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/transformational-designs-web.jpg";
    //     break;
    //   case "/its-showtime":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/its-showtime-web.jpg";
    //     break;
    //   case "/park&ride":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/park-and-ride-web.jpg";
    //     break;
    //   case "/the-third-act":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/the-third-act-web.jpg";
    //     break;
    //   case "/take-five":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/take-five.jpg";
    //     break;
    //   case "/the-annual-excellence-portfolio":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/portfolio-web.jpg";
    //     break;
    //   case "/a-perkins-eastman-city":
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/pe-city.jpg";
    //     break;
    //   default:
    //     imageUrl =
    //       "https://perkinseastman.com/wp-content/uploads/2023/01/narrative-for-social-web.jpg";
    // }
    // const helmetContext = {};

    return (
      // <HelmetProvider context={helmetContext}>
      // {/* <MetaTags location={location.pathname} /> */}

      <TransitionGroup component="div" className="App">
        {/* <Helmet>
          <meta property="og:image" content={imageUrl} />
          <meta property="twitter:image" content={imageUrl} />
        </Helmet> */}
        {/* <MetaTags location={location.pathname} /> */}
        <ScrollToTop />
        <Nav />

        <CSSTransition
          key={currentKey}
          timeout={timeout}
          classNames="pageSlider"
          mountOnEnter={false}
          unmountOnExit={true}
        >
          {/* <CSSTransition
          timeout={400}
          // other props
        > */}
          <div
            className={
              this.getPathDepth(location) - this.state.prevDepth >= 0
                ? "left"
                : "right"
            }
          >
            <Switch location={location}>
              <Route path="/" exact component={Home} />
              <Route path="/TOC" component={TOC} />
              <Route path="/the-age-of-collaboration" component={collab} />
              <Route path="/the-wharf-at-long-last" component={wharf} />
              <Route path="/quick-on-the-draw" component={quick} />
              <Route path="/its-showtime" component={showtime} />
              <Route path="/park&ride" component={park} />
              <Route path="/transformational-designs" component={designs} />
              <Route path="/the-third-act" component={thirdAct} />
              <Route path="/take-five" component={five} />
              <Route path="/the-annual-excellence-portfolio" component={aep} />
              <Route path="/a-perkins-eastman-city" component={city} />
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
      // </HelmetProvider>
    );
  }
}

export default withRouter(App);
