import { React } from "react";
import NavBtns from "../components/NavBtns";
import LazyLoad from "react-lazy-load";

//import images
import Banneker from "../img/annual-excellence-portfolio/Banneker_High_School_80300.00.J_Render_Ext_Entrance_Drive_org_cihexv.jpg";
import Fairleigh from "../img/annual-excellence-portfolio/Fairleigh_Dickinson_University_-_Campus_Union_79840.00.0_Render_Ext_Riverside_View_org_llpntv.jpg";
import Bassick from "../img/annual-excellence-portfolio/render_3_opt_2_high_edited_2_fhvlbu.jpg";

function NewComponent(props) {
  return (
    <div className="page page-container the-annual-excellence-portfolio">
      <NavBtns prev="/take-five" next="/a-perkins-eastman-city" />
      <div className="hero-wrapper">
        <div className="hero">
          <div className="static">
            <div className="hero-text">
              <h1>
                <span>The Annual Excellence </span>Portfolio
              </h1>
            </div>

            <h6>
              An inaugural juried program and publication celebrate the firm’s
              creative energies.
            </h6>
            <h5 className="by-line">by Nick Leahy</h5>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <p>
            The inaugural edition of the <i>Annual Excellence Portfolio</i>—the
            result of a program that recognizes, records, and celebrates design
            and creativity across Perkins Eastman’s 24 studios—will be published
            early next year. The projects in the portfolio, selected by the
            studios themselves as well as an independent jury, represent the
            best design work the firm produced in 2021. The projects will be
            presented in a consistent and complete format based on a set of
            graphic standards developed for the program.
          </p>
          <p>
            As a design firm, Perkins Eastman works across multiple typologies,
            contexts, budgets, and commu-nities. This creates a level of
            complexity that dictates a multidisciplinary, collaborative team
            approach fundamental to how we work. As a result, our work is
            diverse in scale, type, function, and aspect. We don’t ascribe to a
            particular design style or working method. We believe each project
            requires fresh thinking applied through the filter of a rigorous
            design process. We seek unique creative solutions built around the
            needs of people, thereby bringing value to our clients and the
            communities we serve. This is summed up by our Human by Design
            ethos.
          </p>
          <div className="images-single">
            <div className="images-single__img">
              <LazyLoad offset={300}>
                <img
                  src={Banneker}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136489/The%20Narrative%202022/Banneker_High_School_80300.00.J_Render_Ext_Entrance_Drive_org_cihexv.jpg"
                  alt="Benjamin Banneker Academic High School"
                />
              </LazyLoad>

              <span id="banneker">Benjamin Banneker Academic High School</span>
            </div>
            <div className="images-single__quote">
              <p>
                “There’s a marvelous series of terraces that steps down inside
                the building that makes it almost into an indoor street for the
                students to constantly bump into each other and the faculty. . .
                . I think young people are looking for something that’s not same
                old, same old; they want something that’s going to open doors to
                fresh new ways of being in the world, and I think this building
                does that.”
              </p>
              <h5>—Cleveland Harp</h5>
            </div>
          </div>
          <p>
            Design is an evolving practice, one honed and refined as we learn
            from each other and from the work we engage in together and as new
            diverse talent joins our team. The{" "}
            <i>Annual Excellence Portfolio</i> is intended not only as a record
            of the year’s work but also as a reference archive—to learn from
            previous projects and inspire future work.
          </p>
          <p>
            We chose to use the AIA’s Framework for Design Excellence and the
            Perkins Eastman Guiding Principles as the lenses through which to
            assess the work.
          </p>
          <p>
            The 2021 jury—comprising peers and experts from across our
            profession and industry—reviewed 44 submissions and selected nine
            for deeper discussion. The jurors for the 2021 cycle were Betsy del
            Monte, principal consultant at Cameron MacAllister Group, 2021 Chair
            of the AIA Committee on the Environment Advisory Group, and adjunct
            professor at the Lyle School of Engineering, Southern Methodist
            University; Cleveland Harp, university lecturer, Hillier College of
            Architecture and Design, New Jersey Institute of Technology; Ana
            Pinto da Silva, founder and CEO of 2G3R Inc. and director and
            associate teaching professor of Masters in Human Computer
            Interaction + Design, University of Washington; David Sloan, senior
            executive producer, ABC Network Primetime Content; and Joseph
            Wheeler, architecture professor and co-director of the Center for
            Design Research, School of Architecture + Design, Virginia Tech.
          </p>
          <div className="images-single">
            <div className="images-single__quote">
              <p>
                “The architecture is perfectly informed by the bend of the
                river. I love the way it talks to the river in terms of the way
                it graduates down. I think this is a really successful design,
                and it is a great way to incorporate landscape and capitalize on
                topography and have [the building] almost mimic it.”
              </p>
              <h5>—David Sloan</h5>
            </div>
            <div className="images-single__img">
              <LazyLoad offset={300}>
                <img
                  src={Fairleigh}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136569/The%20Narrative%202022/Fairleigh_Dickinson_University_-_Campus_Union_79840.00.0_Render_Ext_Riverside_View_org_llpntv.jpg"
                  alt="Fairleigh Dickinson University Campus Center"
                />
              </LazyLoad>

              <span id="fairleigh">
                Fairleigh Dickinson University Campus Center
              </span>
            </div>
          </div>
          <p>
            The jury selected three finalists: Benjamin Banneker Academic High
            School in Washington, DC; Fairleigh Dickinson University Campus
            Center in Teaneck, NJ; and Bassick High School in Bridgeport, CT.
            Each of the finalist teams presented their projects in a firm-wide
            town hall, and, following a lively discussion, the jury selected
            Benjamin Banneker Academic High School to exemplify the best of our
            work for the year.
          </p>

          <p>
            In the jury’s concluding remarks, Harp ruminated on the program. “I
            find the whole process so admirable—that a number of people at
            Perkins Eastman came together to go out on a limb and do this for
            the first time. All too often there’s not enough conversation in
            firms about the work. They’re so busy doing the work and worrying
            about the next project, as we all have done, that there is not much
            time to discuss [projects]. The idea of building studio and design
            culture is critical in an office.”
          </p>
          <div className="images-single">
            <div className="images-single__img">
              <LazyLoad offset={300}>
                <img
                  src={Bassick}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136665/The%20Narrative%202022/render_3_opt_2_high_edited_2_fhvlbu.jpg"
                  alt="Bassick High School"
                />
              </LazyLoad>

              <span id="bassick">Bassick High School</span>
            </div>
            <div className="images-single__quote">
              <p>
                “I enjoyed how restrained and beautiful the building is, and I
                appreciate the program of the building and the transformative
                impact of the building on the students and the community around
                it.”
              </p>
              <h5>—Ana Pinto da Silva</h5>
            </div>
          </div>
          <p>
            To Harp’s point, the Annual Excellence Portfolio, both the program
            and the annual publication, is a way to measure our progress as a
            design firm and celebrate the creative energies and talents of our
            staff. It’s important that we build an archive that celebrates the
            ideas and work we do together. Our design work and thinking, which
            continuously evolves, is a testimony to the creative energy here at
            Perkins Eastman.
          </p>
          <p>
            On behalf of the Executive Committee, a heartfelt thanks to the
            teams who submitted projects as well as those who developed the
            portfolio’s graphic standards and templates and marshaled the
            submission of materials. The 2022 portfolio cycle is underway, and
            we plan to refine the program and process each year.{" "}
            <strong>N</strong>
          </p>
          <p>
            <strong>
              <i>
                Perkins Eastman Co-CEO and Executive Director Nick Leahy is a
                firm design leader, working on projects around the globe.
              </i>
            </strong>
          </p>

          <p>Renderings Courtesy Perkins Eastman</p>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
