import { React } from "react";
import NavBtns from "../components/NavBtns";
import LazyLoad from "react-lazy-load";

// import images
import BG from "../img/wharf/_M5A049420_v3_Crop-min.jpg";
import Hoff from "../img/wharf/IMG_3338_AR-min.jpg";
import Monty from "../img/wharf/IMG_4891_EDIT_mqfgxb-min.png";
import DuskPier from "../img/wharf/District_Wharf_45680.00.J_Ext_District_Pier_Dusk_HR_org_rulibl-min.jpg";
import StanHilary from "../img/wharf/Stan-Hilary_eyypxr_jah0ku-min.jpg";
import HoffAssoc from "../img/wharf/1667585236008-Phase_1_night_before_fybssv_qv5rsu-min.jpg";
import FooFighters from "../img/wharf/Phase_1_opening-Foo_Fighters_ypuzts-min.jpg";
import Alleyway from "../img/wharf/District_Wharf_45680.00.J_Ext_District_Square_Garage_Entrance_HR_org_ko4hwm-min.jpg";
import Phase2Opening from "../img/wharf/IMG_4132_whno9l-min.jpg";
import Phase1Crew from "../img/wharf/Phase_1_crew_jwf71a-min.jpg";
import JakeLindsay from "../img/wharf/Jake-Lindsay_ytyvkq-min.png";
import Fountain from "../img/wharf/District_Wharf_45680.00.J_Ext_Wharf_Street_Fountain_HR_org_cypen1-min.jpg";
import Fireworks from "../img/wharf/District_Pier_The_Wharf_cropped_vqvryx-min.jpg";
import Channel from "../img/wharf/District_Wharf_-_The_Channel_45684.00.J_Ext_Waterfront_2_HR_org_nj1qbm-min.jpg";
import PierHouse from "../img/wharf/District_Wharf_45680.00.J_Ext_District_Square_Wharf_Street_HR_org_z2t2ap-min.jpg";
import Maine from "../img/wharf/District_Wharf_-_800_Maine_Ave_45683.00.J_Ext_Corner_HR_org_gmgerg-min.jpg";
import Transit from "../img/wharf/45680_00_J_N257_mr_sizu1w-min.jpg";
import Kiosks from "../img/wharf/District_Wharf_45680.00.J_Ext_Wharf_Street_from_Above_1_HR_org_a7kqqa-min.jpg";
import Dockmaster from "../img/wharf/District_Wharf_45680.00.J_Ext_Dockmasters_Building_HR_org_n9wzkc-min.jpg";
import PublicGarage from "../img/wharf/District_Wharf_45680.00.J_Int_Public_Garage_Stairs_HR_org_tcljsi-min.jpg";
import Bikes from "../img/wharf/45680_00_J_N292_mr_zfutmc-min.jpg";

function NewComponent(props) {
  return (
    <div className="page page-container the-wharf-at-long-last">
      <NavBtns prev="/the-age-of-collaboration" next="/quick-on-the-draw" />
      <div className="hero-wrapper">
        <div className="hero ">
          <div className="hero-image">
            <img
              id="hero-bg"
              src={BG}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1668707264/The%20Narrative%202022/the_wharf_lkvc99.jpg"
              alt="The Wharf"
            />
          </div>
          <div className="hero-text">
            <h2>The</h2>
            <h1>Wharf</h1>
            <h3 className="tagline">At Long Last</h3>
            <h6 className="by-line">by Jennifer Sergent</h6>
          </div>
          <h4>
            The Perkins Eastman team and developer Monty Hoffman share stories
            and memories from a 16-year odyssey.
          </h4>
        </div>
        <h6>
          The newly completed Wharf, years in the making, fits beautifully into
          its waterfront context in southwest Washington, DC. Copyright Matthew
          Borkoski/Courtesy Hoffman-Madison Waterfront
        </h6>
      </div>
      <div className="section">
        <div className="static ">
          <p className="caption">
            The excitement was palpable on October 12, 2022. Five years to the
            day after opening the first phase of The Wharf in Washington, DC,
            its developers, designers, contractors, and city officials returned
            to open the second and final phase of the project, completing what
            Washingtonian magazine has termed “the glittering mile of southwest
            waterfront” that has become home to some of the city’s marquee
            businesses and top restaurants, apartments and condos, and hotels.
          </p>
          <div className="single-col">
            {/* <div className="column"> */}
            <p>
              “The assemblage of so many talented professionals in one place
              created what The Wharf is today,” said Shawn Seaman, president of
              The Wharf’s developer, Hoffman & Associates, during a ceremony
              that preceded fireworks and The Killers’ sold-out concert at The
              Anthem. He was standing in front of the new Pendry Hotel, a spot
              that for more than 40 years had been occupied by the three-story
              Channel Inn, one of a smattering of low-lying buildings and
              parking lots along the marina. Few beyond the inn’s guests and the
              boat owners ventured out to the paved walkway that bordered the
              water then. Today, the development draws thousands of DC residents
              and tourists daily.
            </p>
            <p>
              Seated in the audience that October evening were several Perkins
              Eastman leaders who had been involved in the project for a decade
              or more. As The Wharf’s master architect, the firm oversaw dozens
              of other architects, landscape architects, engineers, builders,
              and consultants who contributed to the $3.6 billion endeavor that
              has reimagined the water’s edge along the Washington Channel of
              the Potomac River, stretching between the city’s historic
              Municipal Fish Market and the Fort McNair army post. “I’d like to
              acknowledge in particular Perkins Eastman, the master architect,
              the glue that held all the design teams together for both phases
              of the project,” Seaman noted in his speech.
            </p>
            <div className="images-single">
              <LazyLoad offset={300}>
                <img
                  src={Hoff}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1668630418/The%20Narrative%202022/Chandeliers-Hoffman_Madison_c8ibgf.jpg"
                  alt="The Wharf"
                />
              </LazyLoad>

              <h6>
                Much of The Wharf’s success is the result of its commitment to
                public placemaking, which is rich with landscaping, shaded
                lanterns, and chandeliers. Photograph Courtesy Hoffman-Madison
                Waterfront
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="static">
          <p>
            “That feels great,” says Perkins Eastman Principal Jason Abbey.
            “It’s not often in the developer world where we’re literally getting
            hugs from the developer and the client team. To see that happen
            after the end of such a long run is amazingly gratifying.” A long
            run is right. The city issued its first request for proposal (RFP)
            to redevelop this stretch of waterfront in 2004. Principals Stan
            Eckstut and Hilary Kinder Bertsch, who were with the firm
            Ehrenkrantz Eckstut & Kuhn (EE&K) before it merged with Perkins
            Eastman in 2011, served as the initial design partners for Hoffman &
            Associates in the RFP response that won the project in 2006. The 16
            years of work that followed have resulted in one of the city’s
            liveliest neighborhoods and a world-class destination. Its impact,
            however, goes much deeper in the lives of the Perkins Eastman
            professionals who helped bring it about as they worked side by side
            with developer Monty Hoffman and the constellation of other firms
            that would ultimately send up to 2,000 workers to the site every day
            once construction began in 2014. These are their stories.
          </p>
          <h4>In the Beginning</h4>

          <p>
            It all started, of course, with Monty Hoffman. “Back in 2006, no
            one—[not] even the District of Columbia— knew what lay ahead. I knew
            I was getting into something big, but it was an abstraction,”
            Hoffman says. The first three years were spent acquiring the land
            and water rights from the city and the federal government. The
            ensuing years would see 14 rezoning applications, 1,200 community
            meetings, 1,700 permits pulled, 4,000 inspections, and the largest
            construction loan in DC history. Not to mention the four Acts of
            Congress that were required to make it all happen. “I knew the
            journey would be difficult, but I did not think it would take 16
            years.”
          </p>
          <p>
            Under Eckstut’s vision, which Bertsch aided and managed through to
            completion, their team wrote the regulations and requirements for
            the public realm that surrounds the new buildings along The Wharf,
            in addition to designing several of the key buildings in Phase 1.
            “Stan has a way of thinking about how a layperson can relate to
            things,” says Associate Principal Stephen Penhoet, The Wharf’s
            project manager throughout the initial planning and entitlement
            phases. “He wanted to create this messy environment down at The
            Wharf,” where cars, delivery trucks, pedestrians, and cyclists
            occupy the same network of spaces. “One of the challenges was trying
            to convince people that messiness was a virtue.”
          </p>
          <p>
            The developer and his team were open to it. “In the formative design
            stages, we held small group meetings—five people or less—that I
            would describe as intellectual food fights. We wanted to hear and
            learn from all perspectives,” Hoffman says. For Principal Omar
            Calderón Santiago, the project’s point person for The Channel
            Apartments and The Anthem, “that experience, to me, was significant
            in my growth as an architect. Monty is very hands-on and he has a
            great design sensibility.” But unlike many developers, he adds,
            Hoffman wasn’t looking for everyone to agree with him. As the
            developer explains, “it was an accretive adventure where design
            outcomes were greater than anybody could have imagined on their own.
            I loved it.”
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Monty}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669645309/The%20Narrative%202022/IMG_4891_EDIT_mqfgxb.png"
                alt="Developer Monty Hoffman"
              />
            </LazyLoad>

            <h6>
              Developer Monty Hoffman, founder of Hoffman & Associates,
              celebrates the completion of The Wharf in a ceremony at The Pendry
              Hotel on October 12. Copyright Dan Swartz/Courtesy Hoffman-Madison
              Waterfront
            </h6>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="static">
          <h4>The Process</h4>
          <p>
            “It’s not often you get to build cities, and at the waterfront in
            the nation’s capital? My goodness,” Calderón Santiago says of his
            career-changing experience at The Wharf. But he’s quick to point out
            that “the process overall was not without its horrors,” an
            observation many shared as they described the project’s rocky,
            uncertain path from start to finish. Calderón Santiago identifies
            his most humbling moment as when the US Commission of Fine Arts sent
            his designs for the Channel and Anthem’s exteriors back to the
            drawing board. “In the words of one commissioner,” he recalls,
            “‘This is not how we do buildings.’ It was painful to go through
            that very public process,” although he now recognizes that the
            commission’s rebuke was not a failure, per se, but “just part of the
            iterative process of design.”
          </p>
          <p>
            Perkins Eastman Associate Principal Douglas Campbell, the project
            manager who led the construction phases, calls it “the gauntlet
            project.” He directed a team of 30 Perkins Eastman staff who
            occupied four trailers on the construction site for five years, and
            he oversaw a consultant team that included more than 100 people.
            “This was eight times bigger than anything I’d ever done before,” he
            says. “You have to be the opposite of being a designer who focuses
            on one big idea. Your job is to execute across a bunch of different
            projects,” he adds. “Part of doing a large project is learning what
            the people on your team can do and letting them go do that. There’s
            always some other crisis. There’s always some other phone call. You
            have to broaden your vision.”
          </p>
          <p>
            Abbey had his own trial by fire. When he joined Perkins Eastman in
            2015, he moved directly into the trailers to help Campbell manage
            the contractors. On his first day, he was greeted with a stack of
            146 RFIs, or requests for information, from contractors seeking
            clarification on one matter or another. Addressing them became his
            daily existence. “They literally had a line of contractors every day
            for us to answer questions,” he says. It was a ritual performed to
            the soundtrack of pile drivers ramming piers and bulkheads into the
            water to underpin the new development. And, as they say, that’s not
            all: As the architect responsible for the 1.5 million square feet of
            below-grade structures that now span the development and protect it
            from the Washington Channel, Abbey and the Perkins Eastman team had
            to coordinate with all the architects (including their own) and
            builders who were designing and constructing the site’s 13
            buildings. They accommodated how each one, with its own design and
            program, would connect to the foundation below. “We had to support
            and adapt to all the structures upstairs,” Abbey says. “We engaged
            with all the other entities. We were on the phone with every single
            person for every single parcel to make sure they were getting what
            they needed for their buildings to work.”
          </p>
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={DuskPier}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669644968/The%20Narrative%202022/District_Wharf_45680.00.J_Ext_District_Pier_Dusk_HR_org_rulibl.jpg"
                alt="Wharf Street esplanade"
              />
            </LazyLoad>

            <h6>
              Vehicles and pedestrians share the same “messy” space at The
              Wharf. Placemaking features such as shaded lanterns, trees, and a
              huge firepit made the Wharf Street esplanade an instantly popular
              destination when Phase 1 opened in 2017. Photograph by Andrew
              Rugge/Copyright Perkins Eastman
            </h6>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="static">
          <p>
            Hoffman, meanwhile, was going through a gauntlet just to keep the
            development financed. “The Great Recession drove some of my partners
            into bankruptcy. So I self-funded for a few years,” he says. “In
            that environment, there are many predators. And honestly, with all
            the hurdles to be jumped, I probably wasn’t a good ‘bet’ in the
            beginning.” Yet by the time Phase 1 opened in 2017, the developer
            had established a capital partner in PSP Investments, one of
            Canada’s largest pension investment managers, which gave The Wharf
            the “certainty for both development and long-term ownership” that it
            needed to reach completion, Hoffman says.
          </p>
          <p>
            Bertsch says she’ll never forget the night before Phase 1 opened on
            October 12, 2017. Members of the Perkins Eastman and Hoffman &
            Associates teams gathered to celebrate on what is now the Recreation
            Pier. There was a collective sense of relief in the air. “They had a
            countdown clock, and had booked the Foo Fighters years in advance
            for the opening day. That was the date. There was no blinking,” she
            says. “There was so much going on. I don’t think the Hoffman &
            Associates team slept much during that last month. Two million
            square feet came online in one day. This whole Wharf neighborhood—it
            didn’t exist, and then it did!” As they gathered that evening, she
            says, “that moment, it was life-changing. This was the last moment
            we would have it to ourselves. We were giving it to the world the
            next day.”
          </p>
        </div>
      </div>

      <div className="section">
        <div className="image-cluster row-2">
          <div>
            <LazyLoad offset={300}>
              <img
                src={StanHilary}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1671111988/The%20Narrative%202022/Stan-Hilary_eyypxr_jah0ku.jpg"
                alt="Perkins Eastman Principal Stan Eckstut and Principal and Executive Director Hilary Kinder Bertsch"
              />
            </LazyLoad>
            <h6>
              Perkins Eastman Principal Stan Eckstut and Principal and Executive
              Director Hilary Kinder Bertsch on a rainy Phase 1 opening day.
              Photograph courtesy Hilary Kinder Bertsch
            </h6>
          </div>

          <div>
            <LazyLoad offset={300}>
              <img
                src={HoffAssoc}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1671112052/The%20Narrative%202022/1667585236008-Phase_1_night_before_fybssv_qv5rsu.jpg"
                alt=" Hoffman & Associates team members gathered to celebrate with
                Perkins Eastman colleagues the night before the Phase 1 opening"
              />
            </LazyLoad>
            <h6>
              Hoffman & Associates team members gathered to celebrate with
              Perkins Eastman colleagues the night before the Phase 1 opening.
              Left to right: Yasmine Doumi, former development manager;
              President Shawn Seaman; and Senior Development Manager Lane
              Gearhart. Photograph courtesy Hilary Kinder Bertsch
            </h6>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={FooFighters}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1668630546/The%20Narrative%202022/Phase_1_opening-Foo_Fighters_ypuzts.jpg"
                alt="Foo Fighters concert"
              />
            </LazyLoad>

            <h6>
              The Phase 1 opening day saw widespread delight. The Foo Fighters
              inaugurated The Anthem with a sold-out concert. Photograph
              courtesy Barbara Mullenex
            </h6>
          </div>
          <h3>Life Changes</h3>
          <p>
            Babies became teenagers in the time it took to develop The
            Wharf—including Shawn Seaman’s twin girls, who were born in 2010. As
            Campbell describes the 16-year endeavor, “it was a percentage of our
            lives.” It was a time that saw people get engaged—Perkins Eastman
            Associate Jake Bialek met his wife, Lindsay Scott, while she was
            working as a receptionist for Hoffman & Associates at the
            trailers—but it was also a period that saw tragedy. Perkins
            Eastman’s Blair Phillips, a young architect who was passionate about
            The Wharf, died in a ski accident in 2013. “He had the sun, the
            moon, and the stars ahead of him,” Penhoet says. He was so popular
            at the firm that grief counselors were called in to help his
            colleagues cope with the loss, and a fountain on the waterfront
            esplanade was dedicated to him. Phillips’ parents visited The Wharf
            after his death, and Monty Hoffman met with them in one of the
            trailers. “Right then and there,” Campbell says, Hoffman also
            decided to rename the alley behind the fountain Blair Alley.
          </p>
          <p>
            The Wharf was deeply personal for those who grew up in the area.
            “This project has a lot of emotional significance to me,” says
            Principal Gary Steiner, who co-manages the Washington studio with
            Principal and Executive Director Barbara Mullenex. The Rockville,
            MD, native remembers visiting the Municipal Fish Market as a child
            to purchase crabs with his parents. “At the time, it was a pretty
            seamy experience. It was pretty gritty,” says Steiner, who built the
            teams that worked on the new development. “Seeing this thing happen
            in a place that I was familiar with was pretty significant.”
            Associate Ricardo Hemmings, who worked on the below-grade elements
            for four years, remembers moving with his family from Jamaica to
            Washington, DC, when he was 19. His parents quickly found the
            Municipal Fish Market because it was the only place in the city that
            sold fresh fish, which is how they bought it in the Caribbean.
            Nearly every Saturday since then, they’ve come to the fish
            market—even during The Wharf’s construction. “When we got here
            initially, there were very few things that we felt connected to,”
            Hemmings says. The Caribbean community along Georgia Avenue in
            Northwest DC was one of them—and the fish market was another. “When
            [my parents] found out that I was going to be doing Phase 2, my mom
            was gushing to everyone about it. I was like, ‘Mom, I’m doing the
            garage!’”
          </p>
          <p>
            The project was even more pivotal to Perkins Eastman as a firm.
            There were about 40 people in the DC studio when it merged with EE&K
            and began work on The Wharf in 2011. With Perkins Eastman’s overall
            size and expertise in big buildings, the merger allowed Eckstut and
            Bertsch to go past the planning and strategic phases and into the
            architecture and construction space. In turn, Perkins Eastman
            secured one of its biggest commissions to date. “Nobody [here] had
            ever done anything nearly as large before, either personally or as a
            firm,” Steiner says. “The Wharf was the seminal project that put us
            on the map.” With more than 120 people at the DC studio—and still
            hiring—Mullenex says, “the size we are now is the size where you can
            do anything as a firm—and The Wharf was the ticket.”
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Alleyway}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136542/The%20Narrative%202022/District_Wharf_45680.00.J_Ext_District_Square_Garage_Entrance_HR_org_ko4hwm.jpg"
                alt="District_Square_Garage_Entrance"
              />
            </LazyLoad>

            <h6>
              Perkins Eastman designed The Wharf’s public realm to bring the
              development’s massive size down to human scale. It features myriad
              vignettes such as a shaded seating and play area, below;
              restaurant-lined alleyways hung with twinkling lights and
              chandeliers; and parks that separate many of the buildings.
              Copyright Jeff Goldberg/Esto/Courtesy Perkins Eastmanand
            </h6>
          </div>
          <h3>Sign of Success</h3>
          <p>
            Phase 1 was completed with largely local architecture firms
            responsible for its structures. The Wharf’s immediate success,
            however, paved the way for national and international firms to enter
            the mix and design office and residential buildings for Phase 2.
            Celebrity chefs such as Gordon Ramsay are opening restaurants there,
            and “insane luxury condos,” in the words of Washingtonian, are
            expected to sell north of $12 million. Yet in his speech at the
            Phase 2 opening, Hoffman emphasized the spaces in between the
            buildings—the public realm that Perkins Eastman designed along this
            mile between the fish market and Fort McNair. Hoffman pointed to the
            “warm and soft” wood where people walk along the piers, the
            traffic-calming cobblestones, and the energy that surrounds the
            development’s multiple “town squares.” He noted the trees and
            plantings that provide shade and the chandeliers over the
            streetscapes that have dimmers “so they make you look good at
            night.” There are outdoor firepits to gather around and small stages
            where artists perform as visitors walk by and spill out of the
            restaurants and hotels.
          </p>
          <p>
            “I talk a lot about the tenacity of vision,” Bertsch says,
            explaining that the public realm brings connection and order to
            everything else. “It’s the integrity of the place,” knitting the
            city to its waterfront. “If you keep the fabric of the environment,
            there is continuity in that spirit.” That’s the placemaking theme
            Hoffman was after, he says. “We traveled all over the world to visit
            and study public realms and waterfronts. We wanted to learn what
            worked and what to avoid in creating The Wharf. Today, we give tours
            to people from all over the world who come to The Wharf to study and
            learn how it works. It’s kind of a poetic full circle.”
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static image-collage">
          <div className="images">
            <div className="images-single">
              <LazyLoad offset={300}>
                <img
                  src={Phase2Opening}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136586/The%20Narrative%202022/IMG_4132_whno9l.jpg"
                  alt="Perkins Eastman and Hoffman & Associates celebrating Phase 2"
                />
              </LazyLoad>

              <h6>
                The Perkins Eastman and Hoffman & Associates teams gathered to
                celebrate the opening of Phase 2 at The Wharf on October 12.
                Left to right: Perkins Eastman DC Associate Principal and
                Project Manager Douglas Campbell; Colin Gdula of Hoffman &
                Associates, who handled field construction; Perkins Eastman DC
                Principal Jason Abbey; landscape architect Paul Josey of Wolf
                Josey, who worked under Perkins Eastman DC; Perkins Eastman
                Principal and Executive Director Hilary Kinder Bertsch; building
                construction manager Lane Gearhart of Hoffman & Associates;
                Perkins Eastman Associate Belen Ayarra, the project architect
                for ground plane; Hoffman & Associates Vice President of
                Development Matthew Steenhoek; Hoffman & Associates Development
                Manager Christopher Kirchner; and Hoffman & Associates Senior
                Development Manager Anthony Albanese. Photograph Catherine
                Page/Courtesy Perkins Eastman
              </h6>
            </div>
            <div className="images-single">
              <LazyLoad offset={300}>
                <img
                  src={Phase1Crew}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1668630533/The%20Narrative%202022/Phase_1_crew_jwf71a.jpg"
                  alt="Perkins Eastman and Hoffman & Associates celebrating Phase 1"
                />
              </LazyLoad>

              <h6>
                The Perkins Eastman and Hoffman & Associates teams celebrated
                the Phase 1 opening in 2017. Left to right: Perkins Eastman
                Associate Principal Douglas Campbell; Perkins Eastman Principal
                and Executive Director Barbara Mullenex; retired Perkins Eastman
                Principal Douglas Smith; Perkins Eastman Associate Josh
                Eisenstat (seated above); Hoffman & Associates President Shawn
                Seaman; former Perkins Eastman design architect Evan Smith
                (seated above); Perkins Eastman Principal and Executive Director
                Hilary Kinder Bertsch; former Perkins Eastman Associate Sarah
                Watling; and Perkins Eastman Associate Principal Mathew Hart.
                Photograph courtesy Hilary Kinder Bertsch
              </h6>
            </div>
            <div className="row-2">
              <div className="images-single">
                <LazyLoad offset={300}>
                  <img
                    src={JakeLindsay}
                    // src="https://res.cloudinary.com/nickpug/image/upload/v1669136589/The%20Narrative%202022/Jake-Lindsay_ytyvkq.png"
                    alt="Perkins Eastman Associate Jake Bialek and his wife, Lindsay Scott"
                  />
                </LazyLoad>

                <h6>
                  Perkins Eastman Associate Jake Bialek and his wife, Lindsay
                  Scott, posed at the Christmas tree on The Wharf’s District
                  Pier in 2017. Photograph courtesy Jacob Bialek
                </h6>
              </div>
              <div className="images-single">
                <LazyLoad offset={300}>
                  <img
                    src={Fountain}
                    // src="https://res.cloudinary.com/nickpug/image/upload/v1669136557/The%20Narrative%202022/District_Wharf_45680.00.J_Ext_Wharf_Street_Fountain_HR_org_cypen1.jpg"
                    alt="fountain dedicated to the late Perkins Eastman architect Blair Phillips"
                  />
                </LazyLoad>

                <h6>
                  A fountain is dedicated to the late Perkins Eastman architect
                  Blair Phillips. Photograph by Andrew Rugge/Copyright Perkins
                  Eastman
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="images-single">
            <LazyLoad offset={300}>
              <img
                src={Fireworks}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1671458624/The%20Narrative%202022/District_Pier_The_Wharf_cropped_vqvryx.jpg"
                alt="District Pier The Wharf"
              />
            </LazyLoad>

            <h6>
              Five years to the day after opening Phase 1, fireworks go off
              beyond the Transit Pier, which Perkins Eastman designed, to
              celebrate the completion of The Wharf’s second and final phase.
              Copyright Dan Swartz/Courtesy Hoffman-Madison Waterfront
            </h6>
          </div>
        </div>
      </div>
      <div className="section building-structures">
        <h4>
          <span className="perkins-eastman">Perkins Eastman </span>
          <span>Buildings + Structures</span>
        </h4>
        <div className="image-grid">
          <div className="single-image">
            <div className="images-single">
              <LazyLoad offset={300}>
                <img
                  src={Channel}
                  // src="https://res.cloudinary.com/nickpug/image/upload/v1669136526/The%20Narrative%202022/District_Wharf_-_The_Channel_45684.00.J_Ext_Waterfront_2_HR_org_nj1qbm.jpg"
                  alt="The Channel Apartments and The Anthem Music Hall"
                />
              </LazyLoad>

              <h6>
                The Channel Apartments and The Anthem Music Hall: The 12-story,
                two-tower apartment building embraces The Anthem—an unorthodox
                scheme that disguises what would otherwise be a large,
                windowless box.
              </h6>
            </div>
          </div>
          <div className="single-image">
            <LazyLoad offset={300}>
              <img
                src={PierHouse}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136547/The%20Narrative%202022/District_Wharf_45680.00.J_Ext_District_Square_Wharf_Street_HR_org_z2t2ap.jpg"
                alt="Pier House"
              />
            </LazyLoad>

            <h6>
              Pier House: This freestanding building is located in the center of
              District Square between Maine Avenue and Wharf Street. Besides
              housing the popular ilili Restaurant, it also provides a mask of
              sorts, enticing visitors to move through District Square, walk
              around the building, and discover the water’s edge on the other
              side.
            </h6>
          </div>
          <div className="single-image">
            <LazyLoad offset={300}>
              <img
                src={Maine}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136521/The%20Narrative%202022/District_Wharf_-_800_Maine_Ave_45683.00.J_Ext_Corner_HR_org_gmgerg.jpg"
                alt="800 Maine Avenue"
              />
            </LazyLoad>

            <h6>
              800 Maine Avenue: The 11-story office building, directly across
              District Square from The Channel Apartments, includes street-level
              retail and restaurants.
            </h6>
          </div>
          <div className="single-image">
            <LazyLoad offset={300}>
              <img
                src={Transit}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136465/The%20Narrative%202022/45680_00_J_N257_mr_sizu1w.jpg"
                alt="Transit Pier"
              />
            </LazyLoad>

            <h6>
              Transit Pier: This multipurpose structure houses the box office
              for The Anthem, accommodates ticketing and loading for the Potomac
              Water Taxi, offers public amenities such as tiered outdoor seating
              and public bathrooms, and hosts the rooftop bar Cantina Bambina.
            </h6>
          </div>
          <div className="single-image">
            <LazyLoad offset={300}>
              <img
                src={Kiosks}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136560/The%20Narrative%202022/District_Wharf_45680.00.J_Ext_Wharf_Street_from_Above_1_HR_org_a7kqqa.jpg"
                alt="Wharf Street Kiosks"
              />
            </LazyLoad>

            <h6>
              Kiosks: These small vendor stands are scattered along the water
              side of Wharf Street.
            </h6>
          </div>
          <div className="single-image">
            <LazyLoad offset={300}>
              <img
                src={Dockmaster}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136550/The%20Narrative%202022/District_Wharf_45680.00.J_Ext_Dockmasters_Building_HR_org_n9wzkc.jpg"
                alt="Dockmaster Building"
              />
            </LazyLoad>

            <h6>
              Dockmaster Building: An assembly and event space, with views of
              the water and national monuments, tops the dock manager’s office
              at the end of District Pier.
            </h6>
          </div>
          <p>
            All photographs on this page Copyright Jeff Goldberg/Esto/Courtesy
            Perkins Eastman
          </p>
        </div>
      </div>
      <div className="section">
        <div className="static">
          <div className="hidden-wharf-images">
            <LazyLoad offset={300}>
              <img
                src={PublicGarage}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136564/The%20Narrative%202022/District_Wharf_45680.00.J_Int_Public_Garage_Stairs_HR_org_tcljsi.jpg"
                alt="Two-level garages"
              />
            </LazyLoad>

            <h6>
              Two-level garages and other underground structures run the length
              of The Wharf. An exit at District Square is welcoming and
              ceremonious, instead of hidden away from the action. Photograph by
              Sarah Mechling/Copyright Perkins Eastman
            </h6>
            <LazyLoad offset={300}>
              <img
                src={Bikes}
                // src="https://res.cloudinary.com/nickpug/image/upload/v1669136468/The%20Narrative%202022/45680_00_J_N292_mr_zfutmc.jpg"
                alt="Maine Avenue sidewalks and a bike path separated
              by rain gardens"
              />
            </LazyLoad>

            <h6>
              Perkins Eastman’s design to place utility transformer vaults
              underground made way for an unobstructed pedestrian experience on
              Maine Avenue, which includes sidewalks and a bike path separated
              by rain gardens. Photograph by Andrew Rugge/Copyright Perkins
              Eastman
            </h6>
          </div>

          <h3 className="hidden-wharf">The Hidden Wharf</h3>
          <p>
            Invisible design interventions were critical to allow the smooth
            functioning of The Wharf and its buildings:
          </p>
          <ul>
            <li>
              Under Principal Stan Eckstut’s vision, pedestrians and vehicles
              share the same “messy” spaces; there were to be no curbs or
              gutters separating them. That meant engineering four different
              types of drainage, including rain gardens on Maine Avenue,
              conventional drains in the alleyways, trench drains on Wharf
              Street, and large-scale, landscaped planter beds by the water.
              They all convey stormwater through separators to filter out waste
              and on to four huge, custom-designed underground cisterns, which
              supply water to all the buildings’ cooling systems.
            </li>
            <li>
              The Wharf continues to be a working wharf with a large marina and
              a live-aboard houseboat community. All those boats need fuel. Amid
              the tight confines of the two-level underground parking garage
              that Perkins Eastman designed to stretch underneath the entire
              Wharf complex, there are tanks containing 25,000 gallons of gas
              and diesel fuel to service the boat traffic, in spaces engineered
              to remain secure from storm events.
            </li>
            <li>
              Initially, PEPCO—the local power utility—insisted that 23
              electrical transformer vaults were required along Maine Avenue,
              which would have interrupted a streetscape experience that
              includes a bike path, trees, building entries, commercial
              storefronts, and street lamps. Perkins Eastman’s team spent a year
              developing a design to ventilate the vaults through the garage
              spaces, resulting in a sidewalk with minimal utility intrusions,
              and in the process, reduced development costs. <strong>N</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NewComponent;
