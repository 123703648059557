import { React, useRef } from "react";
import { useState } from "react";
import LazyLoad from "react-lazy-load";

import useDraggableScroll from "use-draggable-scroll";
import ScrollContainer from "react-indiana-drag-scroll";

import "../styles/a-perkins-eastman-city.scss";

//import images
// import BG from "../img/perkins-eastman-city/Blue_wharf_adjusted_sdobx4.jpg";
// import CanalLeft from "../img/perkins-eastman-city/canal_left_rjowph.png";
// import CanalRight from "../img/perkins-eastman-city/canal_right_wvzvxp.png";
import BuildingsLeft from "../img/perkins-eastman-city/PE_building_skyline_2021_alnpoe-left.png";
import BuildingsRight from "../img/perkins-eastman-city/PE_building_skyline_2021_alnpoe-right.png";
import City from "../img/perkins-eastman-city/PE_city_2022_final_adjusted_gub7cr.jpg";
import HumanByDesign from "../img/perkins-eastman-city/PE_HBD_01_White_CMYK_whdi3e.png";
import Logo from "../img/PE_Wordmark_01_White_CMYK_futqsb.png";

function NewComponent(props) {
  const [isActivePanels, setIsActivePanels] = useState(false);
  function handleClickPanels(e) {
    e.preventDefault();
    console.log(e);
    console.log(this);
    setIsActivePanels((current) => !current);
  }
  const [isActiveCity, setIsActiveCity] = useState(false);
  function handleClickCity(e) {
    e.preventDefault();
    console.log(e);
    console.log(this);
    setIsActiveCity((current) => !current);
  }
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref, { direction: "horizontal" });
  return (
    <div className="page page-container a-perkins-eastman-city">
      <div className="hero">
        <div className="left">
          <h1>
            <span className="imagine">Imagine a </span>
            <span className="PE">Perkins Eastman</span>
            <span className="city-text">City</span>
          </h1>
        </div>
        <div className="right">
          <img
            src={HumanByDesign}
            // src="https://res.cloudinary.com/nickpug/image/upload/v1669136652/The%20Narrative%202022/PE_HBD_01_White_CMYK_whdi3e.png"
            alt="Human By Design"
          />
          <img
            src={Logo}
            // src="https://res.cloudinary.com/nickpug/image/upload/v1669136653/The%20Narrative%202022/PE_Wordmark_01_White_CMYK_xzcg4n.png"
            alt="Perkins Eastman"
          />
          <p className="credit">Text by Jennica Deely </p>
          <p className="credit">Drawing by Ling Zhong</p>
          <br />
          <p className="credit-sm">
            Cover photo: The Wharf, Copyright Joseph Romeo/Courtesy Perkins
            Eastman
          </p>
          <p className="credit-sm">
            Interior photo: Buffalo Canalside, Copyright Joe
            Cascio/Canalside/Courtesy Perkins Eastman
          </p>
        </div>
      </div>
      <div className={isActivePanels ? "panels active" : "panels"}>
        <div className="btn-wrapper">
          <button onClick={handleClickPanels}>
            Start
            <br />
            Here
          </button>
        </div>
        <div className="panels-left">
          <div className="buildings">
            <img
              src={BuildingsLeft}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1671124607/The%20Narrative%202022/PE_building_skyline_2021_alnpoe-left-removebg-preview_yvsyma.png"
              alt="skyline buildings"
            />
          </div>
          <div className="text">
            <h3>For more than 40 years . . .</h3>
            <p>
              Perkins Eastman has designed buildings, places, and spaces in
              cities from Manhattan to Mumbai, Dallas to Dubai, and Chicago to
              Shanghai. Enough, in fact, to create an entire metropolis with a
              little help from the firm’s specialty studios along the way. This
              revelation raises intriguing questions. What does such a place
              look like? What makes it vital? What is its sustaining ethos?
              <br />
              <br />
              The imaginary city unfolds like this: On their original sites,
              each design harmonizes climate, context, form, interior, and
              purpose. Some buildings shape the skyline. Others serve as
              background players— understated, yet essential. Some public places
              are big and bustling. Others are intimately scaled and tucked away
              from the action. Woven together as one, the skyscrapers, schools,
              healthcare facilities, office buildings, public markets,
              libraries, senior living communities, transit stations, stadiums,
              residential buildings, performing arts venues, museums, research
              laboratories, plazas, waterfront esplanades, and green spaces
              become a dynamic metropolis.
            </p>
          </div>
        </div>
        <div className="panels-right">
          <div className="buildings">
            <img
              src={BuildingsRight}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1671124634/The%20Narrative%202022/PE_building_skyline_2021_alnpoe-right-removebg-preview_bs2aym.png"
              alt="skyline buildings"
            />
          </div>
          <div className="text">
            <p>
              A Perkins Eastman City, more than a collection of buildings and
              spaces, fosters connections. These interactions happen
              exponentially across the city and throughout the day, minute by
              minute, hour by hour. One intentional meeting leads to one
              serendipitous exchange— or two or three or four. Fun, intense,
              transactional, frivolous, contentious, loving, kind, or
              felicitous, these connections energize the city, and the city is
              energized by them. <br />
              <br />
              At the heart of these connections are people—parents, children,
              teachers, first responders, caregivers, executives, electricians,
              artists, retirees, paralegals, athletes, students, musicians,
              tourists, maintenance workers, curators, carpenters, housekeepers,
              doctors. Their ambitions, their memories, and their actions
              galvanize, sustain, and shepherd the creation and continuous
              reinvention of . . .
            </p>
            <h3>. . . a Perkins Eastman City.</h3>
          </div>
        </div>
      </div>

      <ScrollContainer className={isActiveCity ? "city active" : "city"}>
        <div className="img-wrapper">
          <LazyLoad offset={300}>
            <img
              src={City}
              // src="https://res.cloudinary.com/nickpug/image/upload/v1670851442/The%20Narrative%202022/PE_city_2022_final_adjusted_gub7cr.jpg"
              alt="A Perkins Eastman City"
            />
          </LazyLoad>
          <div className="story">
            <p>
              As dawn breaks over the city, a man sweeps leaves from the transit
              station sidewalk. On the waterfront, a retired couple drinks
              coffee and reads the morning papers. In an apartment nearby, a
              young person opens their laptop to begin work.
            </p>{" "}
            <p>
              Before making his rounds, a senior-living caregiver makes a phone
              call to his partner from the quiet of the staff lounge. On a major
              boulevard, a throng of sign-carrying protesters marches and chants
              for climate action.
            </p>
            <p>
              Across the river, tourists queue for show tickets, while others
              sit on the adjacent glass steps to plan their day. A college
              student grabs lunch in the student center before heading to class.
              During recess, a kindergarten teacher rearranges her classroom for
              afternoon lessons.
            </p>
            <p>
              Later in the day, a father and daughter walk hand-in-hand into the
              stadium to watch the evening soccer match. As daylight fades to
              dusk, commuters stream to mass transit and carpoolers cross the
              bridge, all heading home for dinner.
            </p>
          </div>
        </div>
        <div className="projects">
          <div className="projects-single" id="cleveland-park-library">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Cleveland Park Library</h3>
              <h4>Washington, DC</h4>
            </div>
          </div>

          <div className="projects-single" id="target-field-station">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Target Field Station</h3>
              <h4>Minneapolis, MN</h4>
            </div>
          </div>

          <div className="projects-single" id="banneker-hs">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Benjamin Banneker Academic High School</h3>
              <h4>Washington, DC</h4>
            </div>
          </div>

          <div className="projects-single" id="collection-14">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Collection 14</h3>
              <h4>Washington, DC</h4>
            </div>
          </div>

          <div className="projects-single" id="marinhealth">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>MarinHealth Medical Center</h3>
              <h4>Greenbrae, CA</h4>
            </div>
          </div>

          <div className="projects-single" id="hudson">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>99 Hudson</h3>
              <h4>Jersey City, NJ</h4>
            </div>
          </div>

          <div className="projects-single" id="calpoly">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>CalPoly Pomona Lyle Center for Regenerative Studies</h3>
              <h4>Costa Mesa, CA</h4>
            </div>
          </div>

          <div className="projects-single" id="everton">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Everton Stadium</h3>
              <h4>Liverpool, UK</h4>
            </div>
          </div>

          <div className="projects-single" id="jewish-services">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Jewish Children & Family Services Chicago</h3>
              <h4>Skokie, IL</h4>
            </div>
          </div>

          <div className="projects-single" id="woodside">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Woodside Place</h3>
              <h4>Oakmont, PA</h4>
            </div>
          </div>

          <div className="projects-single" id="koch">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>
                The David H. Koch Center for Cancer Care at Memorial Sloan
                Kettering Cancer Center
              </h3>
              <h4>New York, NY</h4>
            </div>
          </div>

          <div className="projects-single" id="wharf">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>The Wharf</h3>
              <h4>Washington, DC</h4>
            </div>
          </div>

          <div className="projects-single" id="empire">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Empire Stores</h3>
              <h4>Brooklyn, NY</h4>
            </div>
          </div>

          <div className="projects-single" id="one-hotel">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>One Hotel Brooklyn Bridge</h3>
              <h4>Brooklyn, NY</h4>
            </div>
          </div>

          <div className="projects-single" id="tkts">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>TKTS</h3>
              <h4>New York, NY</h4>
            </div>
          </div>

          <div className="projects-single" id="rutgers">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Rutgers University School of Nursing & Science Building</h3>
              <h4>Camden, NJ</h4>
            </div>
          </div>

          <div className="projects-single" id="tenement">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>The Tenement Museum</h3>
              <h4>New York, NY</h4>
            </div>
          </div>

          <div className="projects-single" id="thrugood">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Thurgood Marshall US Courthouse</h3>
              <h4>New York, NY</h4>
            </div>
          </div>

          <div className="projects-single" id="aegis">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Aegis Madison</h3>
              <h4>Seattle, WA</h4>
            </div>
          </div>

          <div className="projects-single" id="gonzaga">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Gonzaga University Myrtle Woldson Performing Arts Center</h3>
              <h4>Spokane, WA</h4>
            </div>
          </div>

          <div className="projects-single" id="john-lewis">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>John Lewis Memorial Bridge</h3>
              <h4>Seattle, WA</h4>
            </div>
          </div>

          <div className="projects-single" id="ashoka">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Ashoka University</h3>
              <h4>Sonipat, India</h4>
            </div>
          </div>

          <div className="projects-single" id="mlk">
            <div className="projects-single__hover-area"></div>
            <div className="projects-single__text">
              <h3>Martin Luther King Jr. School</h3>
              <h4>Boston, MA</h4>
            </div>
          </div>
        </div>
      </ScrollContainer>
      <div
        className={
          isActiveCity ? "btn-wrapper__city active" : "btn-wrapper__city"
        }
      >
        <button onClick={handleClickCity}>{isActiveCity ? "v" : "^"}</button>
      </div>
    </div>
  );
}

export default NewComponent;
